import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import {makeStyles} from "@material-ui/core";



const useStyles = makeStyles({
    header: {
        width: "100vw",
        fontFamily: "'Cinzel', serif",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "13vh",
        textTransform: "uppercase",
        fontSize: "2.2rem",
        letterSpacing: "0.5rem",
        position: "relative",
        // "@media (max-width: 700px)": {
        //     lineHeight: "10vh",
        //     fontSize: "1.6rem",
        //     paddingTop: "20px",

        // },
        // "@media (max-width: 700px) and (orientation: landscape)": {
        //     maginTop: "20px",
        // }
    },
    headerLink: {
        textDecoration: "none",
        color: "rgb(35, 34, 37)",
        marginTop: "1em",
        cursor: "pointer",
        '&:hover': {
            textShadow: "2px 2px 8px rgb(212, 212, 178)",
       } 
    }   
})   


const Header = () => {
    return (
        <Fade top>
            <div className={useStyles().header}>

                <Link to='/' className={useStyles().headerLink}>
                        <p>Marcin Kołpanowicz</p>
                </Link>
            </div>
        </Fade>
    )
}

export default Header;