import {Route, Switch} from "react-router-dom";
import Biography from "../../pages/Biography";
import Blog from "../../pages/Blog";
import Contact from "../../pages/Contact";
import BioExhibitions from "../BioExhibitions/BioExhibitions";
import Gallery from "../../pages/Gallery";
import Cathedral from "../../pages/Gallery_Cathedral";
import InvisibleCities from "../../pages/Gallery_InvisibleCities";
import MythologyOfTheSuburb from "../../pages/Gallery_MythologyOfTheSuburb";
import NewPaintings from "../../pages/Gallery_NewPaintings";
import TheatreOfTheWorld from "../../pages/Gallery_TheatreOfTheWorld";
import TravellersRoom from "../../pages/Gallery_TravellersRoom";
import Giclée from "../../pages/Giclée";
import News from "../../pages/News";
import NotFound from "../../pages/NotFound";
import Reviews from "../../pages/Reviews";


// TODO: MORE ROUTES
const routes = {
  main: {
    path: "/",
    component: Gallery
  },
};


export const Router = () => {
  return (
    <Switch>
      <Route path="/" exact component={Gallery}/>
      <Route path="/travellers-room" component={TravellersRoom}/>
      <Route path="/invisible-cities" component={InvisibleCities}/>
      <Route path="/cathedral" component={Cathedral}/>
      <Route path="/theatre-of-the-world" component={TheatreOfTheWorld}/>
      <Route path="/mythology-of-the-suburb" component={MythologyOfTheSuburb}/>
      <Route path="/new-paintings" component={NewPaintings}/>
      <Route path="/biography" component={Biography}/>
      <Route path="/exhibitions" component={BioExhibitions}/>
      <Route path="/reviews" component={Reviews}/>
      <Route path="/blog" component={Blog}/>
      <Route path="/giclee" component={Giclée}/>
      <Route path="/contact" component={Contact}/>
      <Route path="/news" component={News}/>
      <Route component={NotFound}/>
    </Switch>
  );
};