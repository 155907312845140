import React from 'react';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { makeStyles } from "@material-ui/core";
import Fade from "react-reveal/Fade";


const useStyles = makeStyles({
    // contactContainer: {
    //     width: "60%",
    //     minHeight: "72vh",
    //     margin: "auto",
    //     display: "flex",
    //     flexDirection: "column",
    //     alignItems: "center",
    // },
    contactBox: {
        width: "70%",
        padding: "1em",
        marginTop: "3em",
        textAlign: "center",
        "@media (max-width: 700px)": {
            width: "100%",
            fontSize: "0.8em",
        }
    },
    mediaBox: {
        display: "flex",
        justifyContent: "center",
    },
    contactDetails: {
        lineHeight: "4.5em",
    },
    mediaIcon: {
        color: "rgb(35, 34, 37, 0.6)",
        fontSize: "2em",
        margin: "15px",
        marginTop: "35px",
        cursor: "pointer",
    },
    createdBy:{
        color: "rgb(173, 168, 168)",
        fontSize: "0.7em",
        fontFamily: "Marcellus, serif",
        marginTop: "13em",
    },
    raniuszek: {
        color: "grey",
    }
})


const Contact = () => {
    return (
        <div className='container container--contact'>
            <Fade>
            <div className={useStyles().contactBox}>
                <p className={useStyles().contactDetails}>++48 602 414 067</p>
                <p className={useStyles().contactDetails}>kolpanart@gmail.com</p>
                <div className={useStyles().mediaBox}>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Kolpanart-104860987763030" className="contactLink">
                        <FaFacebook className={useStyles().mediaIcon} />
                    </a>     
                    {/* <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Kolpanart-104860987763030" className="contactLink">
                        <FaInstagram className={useStyles().mediaIcon} />
                    </a> */}
                </div>
            </div>
            <div className={useStyles().createdBy}>created by <span className={useStyles().raniuszek}>raniuszek</span></div>
            </Fade>
        </div>
    )
}

export default Contact;
