import {Grid, makeStyles} from "@material-ui/core";
import React, {useState} from "react";
import {GrNext, GrPrevious} from "react-icons/gr";
import Fade from "react-reveal/Fade";
import {INVimages} from "../components/images";
import {LanguageContext} from "../LanguageContext";





const useStyles = makeStyles({
  galleryImage: {
    width: "25vw",
    height: "auto",
    padding: "0.5rem",
    opacity: "1",
    transition: "opacity 1s",
    '&:hover': {
      cursor: "pointer",
      opacity: "0.7",
    },
    margin: "auto",
    "@media (max-width: 900px)": {
      width: "90vw",
    },
    "@media (max-width: 900px) and (orientation: landscape)": {
      width: "60vw",
    },
  },
  galleryArea: {
    width: "90vw",
    maxHeight: "195vh",
    maxWidth: "100vw",
    display: "flex",
    alignItems: "center",
    marginBottom: "2rem",
    padding: "2.8rem 6rem",
    margin: "auto",
    "@media (max-width: 900px)": {
      maxHeight: "1200vh",
      padding: "0.8rem 2rem",
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      maxHeight: "170vh",
      margin: "auto",
    }
  },
  paintingDetails: {
    backgroundColor: "white",
    fontSize: "1.3em",
    fontWeight: "400",
    opacity: "1",
    textAlign: "center",
    width: "100%",
    position: "absolute",
    left: "0",
    bottom: "50px",
    height: "10%;",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "'Gotu', serif",
    "@media (max-width: 900px)": {
      padding: "15px",
    },
    "@media (max-width: 900px) and (orientation: landscape)": {
      position: "absolute",
      bottom: "10px",
      fontSize: "0.7em"
    }
  },  
})


const GalleryItem = ({image, index, show}) => {
  return (
    <Grid item spacing={5}>
      <img
        key={image.src}
        className={useStyles().galleryImage}
        onClick={() => show(index)} 
        src={image.src}
        alt={image.alt}
      />
    </Grid>  
  );
};


function InvisibleCities() {

  const allImages = INVimages;

  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);



  //function to show a specific image in the lightbox, amd make lightbox visible
  const showImage = (index) => {
    setImageToShow(index);
    // console.log(imageToShow);
   
      setLightBoxDisplay(true);
    
  };

  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  //show next image in lightbox
  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = imageToShow;
    if (currentIndex >= allImages.length - 1) {
      // let nextImage = allImages[0];
      setImageToShow(0);
    } else {
      // let nextImage = allImages[currentIndex + 1];
      setImageToShow(currentIndex + 1);
    }
  };

  //show previous image in lightbox
  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = imageToShow;
    if (currentIndex <= 0) {
      // let nextImage = allImages[allImages.length - 1];
      setImageToShow(allImages.length - 1);
    } else {
      // let nextImage = allImages[currentIndex - 1];
      setImageToShow(currentIndex - 1);
    }
  };

  return (

    <div className="container container--gallery">

      <Fade top>
        <p className="title">
            <LanguageContext.Consumer>{(context) => {
                const { isPolish } = context;
                const title = isPolish ? "Niewidzialne Miasta" : "Invisible Cities"
                return (
                    title
                )
            }}</LanguageContext.Consumer>      
          </p>
      </Fade>
      <Fade>
        <Grid container direction="column"  className={useStyles().galleryArea} justify="center">

   

          {INVimages.map((image, index) => {
            return (
              <GalleryItem
                image={image}
                index={index}
                show={showImage}
              />
            );
          })}
        </Grid>

        {
          lightboxDisplay ?
            <div id="lightbox" onClick={hideLightBox}>
              <button onClick={showPrev}><GrPrevious className="modal__slide-icon"/></button>
              <img id="lightbox-img" src={allImages[imageToShow].src} alt={allImages[imageToShow].alt}></img>
              <button onClick={showNext}><GrNext className="modal__slide-icon"></GrNext></button>
              <div className="painting-details">
                <p> 
                <LanguageContext.Consumer>{(context) => {
                    const { isPolish } = context;
                    const details = isPolish ? [allImages[imageToShow].title.toUpperCase()," ", allImages[imageToShow].details] : [allImages[imageToShow].titleEN.toUpperCase()," ",allImages[imageToShow].detailsEN];
                    return (
                        details
                    )
                }}</LanguageContext.Consumer>      
                </p>
              </div>
            </div>
            : ""
        }
      </Fade>
    </div>
  );
}

export default InvisibleCities;
