import React from 'react';
import {POSTimages} from "../components/images";
import { makeStyles } from "@material-ui/core";
// import DividerMini from '../components/DividerMini/DividerMini';



const useStyles = makeStyles({
    post: {
        width: "65%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "@media (max-width: 900px)": {
            width: "100%",
        },
    },
    postHeader: {
        width: "100%",
        fontWeight: "bold",
        fontSize: "1em",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "5em",
        paddingLeft: "1em",
        paddingRight: "1em",
    },
    postTitle: {
        fontSize: "1.1em",
    },
    postDate: {
        fontSize: "0.9em",
    },
    postText: {
        padding: "1em",
        textAlign: "justify",
        textIndent: "20px",
        "@media (max-width: 800px)": {

        },
    },
    postPhoto: {
        height: "40vh",
        width: "auto",
        float: "left",
        marginTop: "0.5rem",
        marginRight: "2rem",
        marginBottom: "1rem",
        "@media (max-width: 800px)": {
            width: "99%",
            height: "auto",
            marginRight: "0",
            marginLeft: "4em",  
        },
    },
    link: {
        color: "rgb(35, 34, 37)",
        textDecoration: "none",
        fontWeight: "bold",
        cursor: "pointer",
    },
})




const NewsPostEN = () => {
    return (
        <div className={useStyles().post}>
            <div className={useStyles().postHeader}>
            <div className={useStyles().postTitle}>A midsummer night's dream</div>
            <div className={useStyles().postDate}>July 26, 2021</div>
        </div>
        <div className={useStyles().postText}>
            <img src={POSTimages[0].src} alt={POSTimages[0].alt} className='post__photo'></img>
            Mój nowy pastel "Sen nocy letniej" (79 x 70 cm) będzie dostępny 6 sierpnia (piątek) na aukcji <a className={useStyles().link} href="https://artinfo.pl/katalogi-aukcyjne/sztuka-fantastyczna-surrealizm-i-realizm-magiczny-93275f95-a763-4f84-95e9-47742d506dd7?fbclid=IwAR30j6JKnIZniW6VkvTgTXWePOG7FteyVTt0ZedNOm0XdEbrsKspP6M1wpo" target="_blank" rel="noreferrer">Desa Unicum</a> w Grand Hotelu w Sopocie. Numer w katalogu aukcyjnym: 44.
            {/* <DividerMini /> */}
        </div>
    </div>
    )
};


export default NewsPostEN;