import React from "react";
// import "./BioFoto.css";
import {Grid} from "@material-ui/core";
import MKfoto from "../../../src/img/MK.jpg";
import {makeStyles} from "@material-ui/core";



const useStyles = makeStyles({
    bioFoto: {
        height: "65vh",
        width: "auto",
        boxShadow: "none",
        paddingTop: "0.2rem",
        paddingRight: "0.3rem",
    },
    bioFotoBox: {
        "@media (max-width: 950px)":{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }
    }
})




const BioFoto = () => {
    return (
        <Grid item xs={11} md={4}> 
            <div className={useStyles().bioFotoBox}>
                <img src={MKfoto}  className={useStyles().bioFoto} alt='Marcin Kołpanowicz'/>
            </div>   
        </Grid>  
    )
};

export default BioFoto;


