import React from 'react';
import './Divider.css';

const Divider = () => {
  
    return (
        <div className="divider div-transparent"></div>
    )
}

export default Divider;