import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";
import { LanguageContext } from '../../LanguageContext';
import LanguageToggle from "../../LanguageToggle";




const useStyles = makeStyles({
    navbar: {
        width: "100vw",
        height: "7vh",
        display: "flex",
        justifyContent: "center",
    },
    navBar: {
        display: "inline",
        margin: "1.9vw",
        textTransform: "uppercase",
        fontFamily: "Cinzel, serif",
        "@media (max-width: 700px)": {
            display: "flex",
            justifyContent: "center"
        }
    },
    navLink: {
        textDecoration: "none",
        color: "rgb(35, 34, 37)",
        fontSize: "1.3rem",
        cursor: "pointer",
        '&:hover': {
            textShadow: "2px 2px 8px rgb(179, 179, 146)"
        }
    },
    active: {
        borderBottom: "0.05rem solid black",
        fontWeight: "bold",
    }
})


const Menu = ({isOpen}) => {
    return (
        <ul className={useStyles().navList}>  
            {/* <li>
                <LanguageToggle />
            </li>         */}
            <li className={useStyles().navBar}>    
                <NavLink exact to='/' className={useStyles().navLink} activeClassName={useStyles().active}>
                    <LanguageContext.Consumer>{(context) => {
                        const { isPolish } = context;
                        const gallery = isPolish ? "Galeria" : "Gallery";
                        return (
                            gallery
                        )
                    }}</LanguageContext.Consumer>
                </NavLink>  
            </li>
            <li className={useStyles().navBar}>    
                <NavLink to='/news' className={useStyles().navLink} activeClassName={useStyles().active}>
                     <LanguageContext.Consumer>{(context) => {
                        const { isPolish } = context;
                        const news = isPolish ? "Aktualności" : "News";
                        return (
                            news
                        )
                    }}</LanguageContext.Consumer>
                </NavLink>  
            </li>
            <li className={useStyles().navBar}>    
                <NavLink to='/giclee' className={useStyles().navLink} activeClassName={useStyles().active}>
                    Giclée
                </NavLink>  
            </li>
            <li className={useStyles().navBar}>    
                <NavLink to='/blog' className={useStyles().navLink} activeClassName={useStyles().active}>
                    Blog
                </NavLink>  
            </li>
            <li className={useStyles().navBar}>    
                <NavLink to='/biography' className={useStyles().navLink} activeClassName={useStyles().active}>
                    Bio
                </NavLink>  
            </li>
            <li className={useStyles().navBar}>    
                <NavLink to='/contact' className={useStyles().navLink} activeClassName={useStyles().active}>
                    <LanguageContext.Consumer>{(context) => {
                        const { isPolish } = context;
                        const contact = isPolish ? "Kontakt" : "Contact";
                        return (
                            contact
                        )
                    }}</LanguageContext.Consumer>
                </NavLink>  
            </li>
        </ul>
    )
}

export default Menu;
