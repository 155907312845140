import React from 'react'
import Fade from "react-reveal/Fade";
import {LanguageContext} from "../LanguageContext";

const Giclée = () => {
    return (
        // <div className='container'>
        //     <Fade>
               
        //     </Fade>
        // </div>
        // Tymczasowo:
        <div className='container container--blog'>
            <Fade>
            <div className='post'>
                <LanguageContext.Consumer>{(context) => {
                        const { isPolish } = context;
                        const comingSoon = isPolish ? "Dział w budowie" : "Coming soon";
                        return (
                            comingSoon
                        )
                }}</LanguageContext.Consumer>  
            </div>
            </Fade>
        </div>
    )
}

export default Giclée;
