import React from 'react'
import Fade from "react-reveal/Fade";
import {LanguageContext} from "../LanguageContext";

// TO DO: dodać style


const NotFound = () => {
    return (
        <div className='container'>
            <Fade>
                <LanguageContext.Consumer>{(context) => {
                    const { isPolish } = context;
                    const title = isPolish ? "Niestety coś poszło nie tak" : "Sorry, something went wrong"
                    return (
                        title
                    )
                }}</LanguageContext.Consumer>      
            </Fade>
        </div>
    )
}

export default NotFound;
