import React from "react";
import BioNav from "../components/BioNav/BioNav";
import Fade from "react-reveal/Fade";
import {LanguageContext} from "../LanguageContext";

const Reviews = () => {
  return (
    <>
      <BioNav/>
      <div className="container container--contact">
        <Fade>
        <div className='post'>
                <LanguageContext.Consumer>{(context) => {
                        const { isPolish } = context;
                        const comingSoon = isPolish ? "Dział w budowie" : "Coming soon";
                        return (
                            comingSoon
                        )
                }}</LanguageContext.Consumer>  
            </div>
        </Fade>
      </div>
    </>
  );
}

export default Reviews;
