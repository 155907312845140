import React from 'react';
import { makeStyles } from "@material-ui/core";
import DividerMini from '../components/DividerMini/DividerMini';
import {BLOGimages} from "../components/images";

const useStyles = makeStyles({
    post: {
        width: "65%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "@media (max-width: 900px)": {
            width: "100%",
        },   
    },
    postHeader: {
        width: "100%",
        fontWeight: "bold",
        fontSize: "1em",
        display: "flex",
        justifyContent: "space-between",
        margin: "1em",
        marginTop: "5em",
        paddingLeft: "1em",
        paddingRight: "1em",
    },
    postTitle: {
        fontSize: "1.1em",
    },
    postDate: {
        fontSize: "0.9em",
    },
    postText: {
        padding: "1em",
        textAlign: "justify",
        textIndent: "20px",
    },
    // postPhoto: {
    //     height: "40vh",
    //     width: "auto",
    //     float: "left",
    //     marginTop: "0.5rem",
    //     marginRight: "2rem",
    //     marginBottom: "1rem",
    //     "@media (max-width: 800px)": {
    //         width: "90vw",
    //         height: "auto",
    //         margin: "auto",
    //     },
    // },
    blogPhoto: {
        height: "15vh",
        width: "auto",
        float: "left",
        marginTop: "0.5rem",
        marginRight: "2rem",
        marginBottom: "1rem",
        cursor: "pointer",
        "@media (max-width: 800px)": {
            width: "100%",
            height: "auto",
            marginRight: "0",
            // marginLeft: "4em",
        },
    },
})


const BlogPostPL = () => {
    return (

        <>
            <div className='post'>
                <div className='post__header'>
                    <div className='post__title'>Kalendarz z bykiem</div>
                    <div className='post__date'>28 stycznia 2022 r.</div>
                </div>
                <div className='post__text'>
                    <img src={BLOGimages[1].src} alt={BLOGimages[1].alt} className='blog__photo'></img>
                    Z końcem 2021 r. ukończyłem tetraptyk (czyli dzieło złożone z 4 części) „Tańczące anioły 
                    – cztery pory roku” (w technice olejnej). Pomyślałem, że wydam mały kalendarz ścienny z tymi
                    obrazami, bo są jak najbardziej na temat. Najpierw chciałem zaprojektować 4 strony 
                    – odpowiednio do pór roku – ale nie za bardzo to wychodziło, bo pory roku nie pokrywają się 
                    z kwartałami. Więc zdecydowałem się na jedną planszę. W trakcie projektowania 10 razy 
                    sprawdzałem, czy nie ma błędu w kalendarium. No i nie zauważyłem, że byk jest w nagłówku
                    – wpisałem 2021 zamiast 2022! Trzeba było skorygować i zrobić dodruk. A wszystkim nam życzę, 
                    żebyśmy w 2022 byli o rok młodsi!
                    <DividerMini />
                </div>
            </div>
            
            <div className='post'>
                <div className='post__header'>
                    <div className='post__title'>Szum wokół malarstwa</div>
                    <div className='post__date'>20 stycznia 2022 r.</div>
                </div>
                <div className='post__text'>
                    <img src={BLOGimages[0].src} alt={BLOGimages[0].alt} className='blog__photo'></img>
                    W 34 numerze magazynu „Szum” pojawiła się reprodukcja mojego obrazu „Łowca chmur”, 
                    jako ilustracja tekstu Wiktorii Kozioł „Homary, Jastrzębie, Gołębie. Zwrot konserwatywny 
                    w sztuce polskiej”. Ha, ha, niedawno Sarzyński w „Polityce” oburzał się na komercyjne 
                    sukcesy współczesnego magicznego realizmu, teraz Kozioł w „Szumie”  na prawie 30 stronach A4 (!) 
                    „rozprawia się” z powrotem do tradycji we współczesnej sztuce polskiej. Tekst Kozioł domaga się 
                    szczegółowej polemiki, na razie napiszę tylko, że jest on czymś pomiędzy wypracowaniem pilnej 
                    uczennicy a raportem oficera śledczego. Jednak przy pozorach sumienności, Kozioł pomija wiele 
                    istotnych faktów, instytucji i nazwisk; przy pozorach obiektywnej analizy teoretycznej, 
                    przedstawia bałamutny opis zjawisk, pełen niedomówień, przemilczeń i insynuacji. Środowisko 
                    konserwatywne jest tu postrzegane bardzo szeroko – właściwie wszystko, co nie jest entuzjastyczną 
                    promocją neomarksizmu i sztuki krytycznej, Kozioł określa jako konserwatywne i prawicowe. Swoich 
                    adwersarzy pani redaktor opisuje za pomocą metafor zwierzęcych - zapożyczonych z ekonomicznej 
                    poetyki - Jastrzębi i Gołębi oraz zainspirowanych wykładami Jordana Petersona Homarów. Takie 
                    uzwierzęcenie przeciwnika jest zabiegiem dość prymitywnym, tym bardziej, że Kozioł nie rozumie 
                    w jakim celu Peterson posłużył się przykładem homarów – dla niej są to stworzenia bezwolne, 
                    zagubione i niepewne, czekające na lepszy los, podczas gdy Petersonowi homary posłużyły dla 
                    poparcia słuszności i pożyteczności idei hierarchii. Wkrótce przedstawię bardziej szczegółową 
                    polemikę z tezami Kozioł, na razie mogę tylko wyrazić satysfakcję, że sukcesy aukcyjne porządnej 
                    sztuki warsztatowej kolą w oczy lewacką krytykę do tego stopnia, że zrezygnowała z taktyki 
                    przemilczania i zaczyna kąsać.
                    <DividerMini />
                </div>
            </div> 


            <div className={useStyles().post}>
                <div className={useStyles().postHeader}>
                    <div className={useStyles().postTitle}>Sztuka w czasach zarazy</div>
                    <div className={useStyles().postDate}>29 lipca 2021&nbsp;r.</div>
                </div>
                <div className={useStyles().postText}>
                    <p>
                        Jest już najnowszy numer kwartalnika "Artysta i Sztuka", a w nim mój felieton "Sztuka w czasach zarazy". Załączam darmową próbkę: 
                    </p>
                    <br/>
                    <p>
                        "W naszym pełnym inwencji narodzie nigdy na szczęście nie brakowało pomysłów na obchodzenie przepisów. Tak stało się i tym razem. Oto podczas lockdownu klienci restauracji zamienili się w ich pracowników: byli mianowicie zatrudniani przez restauratorów na umowę o dzieło jako testerzy smaku, którzy po konsumpcji otrzymują wynagrodzenie w wysokości 1 złotego – można by rzec: napiwek à rebours. Miłośników sportów wodnych Polski Związek Pływacki powołał do kadry narodowej, dzięki czemu mogli znów regularnie korzystać z zamkniętych dotąd basenów. Z kolei siłownie zamieniono w trybie pilnym na świątynie, w których takie na przykład wyciskanie sztangi stało się praktyką religijną nowo założonego Kościoła Zdrowego Ciała. (...)
                    </p>
                    <p>
                        A dlaczego, u licha, przez kilka miesięcy zamknięte były muzea i galerie? Przecież tam i tak nikt nie chodził, a potencjalnym, choć mało prawdopodobnym zagęszczeniom publiczności można by łatwo zapobiec, organizując chociażby limitowaną sprzedaż biletów przez internet. W ten sposób podczas lockdownu galerie mogłyby − niechby i na ograniczoną skalę − prowadzić swą działalność, a być może zyskałyby nawet kilku dodatkowych zwiedzających, bo wybrałyby się tam z braku lepszego zajęcia osoby, które dotąd wystawy omijały szerokim łukiem. Galerie i muzea zdecydowano się otworzyć dopiero 1 lutego 2021, a i to „na podstawie bardzo wnikliwych ekspertyz i badań”, i tylko na próbę. Minister Kultury, Dziedzictwa Narodowego i Sportu, profesor Piotr Gliński, zauważył wówczas z odkrywczą miną, że „obiekty kulturalne są bezpieczniejsze niż siłownie i restauracje”. Może i są, choć moim zdaniem nie wszystkie, bo na pewno zagrożenia zdrowia psychicznego mniejsze jest na siłowni czy w restauracji niż w takim na przykład Mocaku lub innych centrach sztuki nowoczesnej. Ale to odrębny temat.
                        O ile na branżę muzyczną wirus sprowadził bessę, o tyle w tym samym okresie branża plastyczna przeżywała hossę. I to zarówno, jeśli chodzi o czynne uprawianie twórczości, jak i jej rynkowe konsekwencje. "Dla artysty bycie normalnym to katastrofa" − mówi stara mądrość, więc też malarze są często tak asocjalnymi jednostkami, że czas lock-downu nie różnił się dla nich (właściwie powinienem napisać: dla nas) prawie niczym od zwykłego trybu funkcjonowania. I tak siedzimy dniami i nocami zamknięci w swoich pracowniach, a w przymusowym lockdownie rośnie nam co najwyżej wydajność z hektara, choćby dzięki mniejszej ilości odrywających od malowania pokus  (czytaj:  spotkań towarzyskich, plenerów czy podróży). Głupio to powiedzieć, ale wreszcie mieliśmy idealne warunki do pracy! (Znajomy profesor ASP podzielił się ze mną obserwacją, że w czasie zdalnego studiowania jego uczniowie namalowali w domu o wiele lepsze obrazy, niż miało to miejsce w trakcie regularnych zajęć na uczelni)".
                    </p>
                    {/* <DividerMini /> */}
                </div>
            </div> 
        </>
    )
};

export default BlogPostPL;

            // <div className='post'>
            //     <div className='post__header'>
            //         <div className='post__title'>Lorem ipsum dolor sit amet</div>
            //         <div className='post__date'>23 maja 2021 r.</div>
            //     </div>
            //     <div className='post__text'>
            //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam congue mi sodales ex tincidunt pulvinar. Aliquam et diam vel libero cursus pretium ut vel quam. Mauris purus justo, maximus eget lobortis nec, fermentum et ex. Duis euismod lobortis elit sit amet fringilla. Curabitur id ipsum at metus ornare sollicitudin. Etiam malesuada est at erat aliquet pretium. Ut malesuada ultricies libero, sed blandit orci tempus et. Aliquam commodo tincidunt tellus in convallis. In eget varius dui, ac condimentum nisi.
            //         Donec dapibus aliquet ex, in mollis metus finibus non. In vehicula odio vel consectetur bibendum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec tincidunt varius nisi non tempus. Fusce eu nisl molestie, dapibus libero quis, rutrum velit. Ut ex urna, aliquet et venenatis faucibus, pulvinar ac mauris. Phasellus consectetur enim vel mi egestas semper. Curabitur dignissim felis nec elit faucibus mattis. Sed rhoncus pulvinar nibh ac imperdiet. Sed vitae ipsum bibendum, congue mi vitae, viverra ex. Aenean a dignissim mauris, eget pellentesque odio. Curabitur et tempor augue. Pellentesque nec felis arcu.
            //         Suspendisse gravida arcu libero, sit amet iaculis enim tempor gravida. Nulla lectus justo, tristique ac risus in, auctor fermentum erat. Praesent auctor, lacus eget bibendum bibendum, purus ipsum lobortis metus, sed ultricies tortor arcu at velit. Phasellus efficitur vel dolor eget maximus. Nunc sed tellus interdum, ullamcorper elit eu, malesuada dui. Maecenas sagittis ante quis lectus mattis, nec consectetur neque imperdiet. Fusce auctor consequat massa, a aliquet sapien faucibus eget. Vivamus arcu elit, consectetur vitae metus ac, bibendum eleifend eros. In tincidunt augue purus, vel pellentesque magna feugiat id. Mauris vulputate est ac auctor varius. Nam at rhoncus mauris. Morbi pretium in odio convallis sagittis. Morbi sagittis ac diam vel blandit. Nullam metus dolor, tristique eu vulputate non, gravida eu diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Cras non neque vitae diam mattis tristique in vitae lacus.
            //         Suspendisse potenti. Sed sit amet lorem leo. Nulla interdum arcu ac tellus convallis, id sodales tortor iaculis. Etiam in eros tempus, rhoncus tellus semper, ornare neque. In lorem orci, efficitur non commodo sit amet, molestie id diam. Sed vestibulum mauris at risus consequat, a pharetra neque porttitor. Praesent tempor libero eget risus tincidunt, sit amet feugiat tellus pharetra.
            //         Nulla luctus odio sed dolor viverra ornare. Nullam nulla lectus, tincidunt ac accumsan quis, aliquam eu arcu. Nullam ac venenatis mauris. Aliquam nisi nibh, aliquet ut enim non, sagittis aliquet lectus. Aliquam vestibulum tincidunt ex nec euismod. Cras pretium tempus quam a lacinia. Sed nec massa convallis, porta dolor quis, varius sem. Proin rutrum finibus diam at malesuada. Duis dolor massa, porttitor id risus eget, mollis pharetra nisl. Fusce consectetur efficitur aliquet. Sed ex metus, tempor a consequat ac, rhoncus interdum nisi. Mauris finibus justo quis lorem mattis, in tempor mauris pulvinar. Nam venenatis sollicitudin tortor ornare scelerisque. Mauris euismod convallis turpis. Nam vehicula libero et orci blandit accumsan.
            //         <DividerMini />
            //     </div>
            // </div>
            
            // <div className='post'>
            //     <div className='post__header'>
            //     <div className='post__title'>Nullam nulla lectus</div>
            //         <div className='post__date'>20 maja 2021 r.</div>
            //     </div>
            //     <div className='post__text'>
            //         Nulla lectus justo, tristique ac risus in, auctor fermentum erat. Praesent auctor, lacus eget bibendum bibendum, purus ipsum lobortis metus, sed ultricies tortor arcu at velit. Phasellus efficitur vel dolor eget maximus. Nunc sed tellus interdum, ullamcorper elit eu, malesuada dui. Maecenas sagittis ante quis lectus mattis, nec consectetur neque imperdiet. Fusce auctor consequat massa, a aliquet sapien faucibus eget. Vivamus arcu elit, consectetur vitae metus ac, bibendum eleifend eros. In tincidunt augue purus, vel pellentesque magna feugiat id. Mauris vulputate est ac auctor varius. Nam at rhoncus mauris. Morbi pretium in odio convallis sagittis. Morbi sagittis ac diam vel blandit. Nullam metus dolor, tristique eu vulputate non, gravida eu diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Cras non neque vitae diam mattis tristique in vitae lacus.
            //         Suspendisse potenti. Sed sit amet lorem leo. Nulla interdum arcu ac tellus convallis, id sodales tortor iaculis. Etiam in eros tempus, rhoncus tellus semper, ornare neque. In lorem orci, efficitur non commodo sit amet, molestie id diam. Sed vestibulum mauris at risus consequat, a pharetra neque porttitor. Praesent tempor libero eget risus tincidunt, sit amet feugiat tellus pharetra.
            //         Nulla luctus odio sed dolor viverra ornare. Nullam nulla lectus, tincidunt ac accumsan quis, aliquam eu arcu. Nullam ac venenatis mauris. Aliquam nisi nibh, aliquet ut enim non, sagittis aliquet lectus. Aliquam vestibulum tincidunt ex nec euismod. Cras pretium tempus quam a lacinia. Sed nec massa convallis, porta dolor quis, varius sem. Proin rutrum finibus diam at malesuada. Duis dolor massa, porttitor id risus eget, mollis pharetra nisl. Fusce consectetur efficitur aliquet. Sed ex metus, tempor a consequat ac, rhoncus interdum nisi. Mauris finibus justo quis lorem mattis, in tempor mauris pulvinar. Nam venenatis sollicitudin tortor ornare scelerisque. Mauris euismod convallis turpis. Nam vehicula libero et orci blandit accumsan.
            //         Nulla lectus justo, tristique ac risus in, auctor fermentum erat. Praesent auctor, lacus eget bibendum bibendum, purus ipsum lobortis metus, sed ultricies tortor arcu at velit. Phasellus efficitur vel dolor eget maximus. Nunc sed tellus interdum, ullamcorper elit eu, malesuada dui. Maecenas sagittis ante quis lectus mattis, nec consectetur neque imperdiet. Fusce auctor consequat massa, a aliquet sapien faucibus eget. Vivamus arcu elit, consectetur vitae metus ac, bibendum eleifend eros. In tincidunt augue purus, vel pellentesque magna feugiat id. Mauris vulputate est ac auctor varius. Nam at rhoncus mauris. Morbi pretium in odio convallis sagittis. Morbi sagittis ac diam vel blandit. Nullam metus dolor, tristique eu vulputate non, gravida eu diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Cras non neque vitae diam mattis tristique in vitae lacus.
            //         Suspendisse potenti. Sed sit amet lorem leo. Nulla interdum arcu ac tellus convallis, id sodales tortor iaculis. Etiam in eros tempus, rhoncus tellus semper, ornare neque. In lorem orci, efficitur non commodo sit amet, molestie id diam. Sed vestibulum mauris at risus consequat, a pharetra neque porttitor. Praesent tempor libero eget risus tincidunt, sit amet feugiat tellus pharetra.
            //         Nulla luctus odio sed dolor viverra ornare. Nullam nulla lectus, tincidunt ac accumsan quis, aliquam eu arcu. Nullam ac venenatis mauris. Aliquam nisi nibh, aliquet ut enim non, sagittis aliquet lectus. Aliquam vestibulum tincidunt ex nec euismod. Cras pretium tempus quam a lacinia. Sed nec massa convallis, porta dolor quis, varius sem. Proin rutrum finibus diam at malesuada. Duis dolor massa, porttitor id risus eget, mollis pharetra nisl. Fusce consectetur efficitur aliquet. Sed ex metus, tempor a consequat ac, rhoncus interdum nisi. Mauris finibus justo quis lorem mattis, in tempor mauris pulvinar. Nam venenatis sollicitudin tortor ornare scelerisque. Mauris euismod convallis turpis. Nam vehicula libero et orci blandit accumsan.
            //     </div>
            // </div> 