import React from 'react';
import Fade from "react-reveal/Fade";
// import DividerMini from '../components/DividerMini/DividerMini';
import { makeStyles } from "@material-ui/core";
import {LanguageContext} from "../LanguageContext";
import BlogPostPL from './BlogPostPL';
import BlogPostEN from './BlogPostEN';



const useStyles = makeStyles({
    postBox: {
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "@media (max-width: 900px)": {
            width: "100%",
        },
        
    }
});



const Blog = () => {
    
    return (
        <div className='container container--blog'>
            <Fade>
            <div className={useStyles().postBox}>
            <LanguageContext.Consumer>{(context) => {
                    const { isPolish } = context;
                    const blogPost = isPolish ? <BlogPostPL/> : <BlogPostEN/>;
                    return (
                        blogPost
                    )
            }}</LanguageContext.Consumer>  
            </div>
        </Fade> 
        </div>
    )
}

export default Blog;