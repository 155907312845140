import React from 'react';
import { makeStyles } from "@material-ui/core";




const useStyles = makeStyles({
bioPara: {
    marginBottom: "1rem",
    textIndent: "20px",
    textAlign: "justify",
    lineHeight: "1.8rem",
  },

})


const BioTextEN= () => {
    return (
        <>
            <p className={useStyles().bioPara}>
           English text
            </p>
            
        </>
    )
}

export default BioTextEN;