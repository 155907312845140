import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import '../App.css';
import Fade from 'react-reveal/Fade';
// import Line from '../components/Line';
// import Footer from '../components/Footer';
import {makeStyles} from "@material-ui/core";
import {LanguageContext} from "../LanguageContext";


const useStyles = makeStyles({
    mainGallery: {
        fontFamily: "'Marcellus', serif",
        fontSize: "1.1rem",
        lineHeight: "1.8rem",
        minHeight: "71vh",
        width: "100vw",
        padding: "3vh 11vw",
        overflowX: "hidden",
        margin: "0",
    },
   
    // gridItem: {

    // },
    // galleryLink: {

    // },
    // galleryIntro: {
    //     height: "14rem",
    //     width: "19rem",
    //     backgroundColor: "rgb(145, 177, 175)",
    //     backgroundSize: "cover",
    //     backgroundPosition: "center",
    //     backgroundRepeat: "no-repeat",
    //     boxShadow: "cadetblue",
    //     opacity: "1",
    //     transition: "opacity 1s",
    //     position: "relative",
    //     marginTop: "15px",
    //     '&:hover': {
    //         opacity: "0.7",
    //    }
    // },
    // introOne: {
    //     backgroundImage: "url(../src/img/covers/pokoj_podroznika.jpg)",

    // },
    // introTwo: {
    //     backgroundImage: "url(../src/img/covers/niewidzialne_miasta.jpg)
    // },
    // introThree: {

    // },
    // introFour: {

    // },
    // introFive: {

    // },
    // introSix: {

    // },
    // overlayTitle: {
    //     position: "absolute",
    //     bottom: "0",
    //     background: "rgb(35, 34, 37, 0.5)",
    //     width: "100%",
    //     color: "white",
    //     fontSize: "1.2rem",
    //     padding: "0.3rem",
    //     textAlign: "center",
    //     textTransform: "uppercase",
    // },

})


const Gallery = () => {
    return (
        <div className={useStyles().mainGallery}>
            <Fade>
        
            <Grid container direction='row' spacing={1} >
                <Grid item xs={12} md={6} lg={4} className='grid-item'>
                    <Link to='/invisible-cities' className='gallery-link'>
                        <div className='gallery-intro intro-two'>
                            <div className='overlay-title'>
                                <LanguageContext.Consumer>{(context) => {
                                    const { isPolish } = context;
                                    const title = isPolish ? "Niewidzialne miasta" : "Invisible Cities"
                                    return (
                                        title
                                    )
                                 }}</LanguageContext.Consumer>
                            </div>
                        </div>
                    </Link>  
                </Grid>
                <Grid item xs={12} md={6} lg={4} className='grid-item'>
                    <Link to='/travellers-room' className='gallery-link'>
                        <div className='gallery-intro intro-one'>
                            <div className='overlay-title'>
                                <LanguageContext.Consumer>{(context) => {
                                    const { isPolish } = context;
                                    const title = isPolish ? "Pokój podróżnika" : "Traveller's Room"
                                    return (
                                        title
                                    )
                                 }}</LanguageContext.Consumer> 
                            </div>
                        </div>
                    </Link>  
                </Grid>
                <Grid item xs={12} md={6} lg={4} className='grid-item'>
                    <Link to='/cathedral' className='gallery-link'>
                        <div className='gallery-intro intro-three'>
                            <div className='overlay-title'>
                                <LanguageContext.Consumer>{(context) => {
                                    const { isPolish } = context;
                                    const title = isPolish ? "Katedra" : "Cathedral"
                                    return (
                                        title
                                    )
                                 }}</LanguageContext.Consumer> 
                                </div>
                        </div>
                    </Link> 
                </Grid>
                <Grid item xs={12} md={6} lg={4} className='grid-item'>
                    <Link to='/theatre-of-the-world' className='gallery-link'>
                        <div className='gallery-intro intro-four'>
                            <div className='overlay-title'>
                                <LanguageContext.Consumer>{(context) => {
                                    const { isPolish } = context;
                                    const title = isPolish ? "Teatr Świata" : "Theatre Of The World"
                                    return (
                                        title
                                    )
                                 }}</LanguageContext.Consumer> 
                            </div>
                        </div>    
                    </Link>  
                </Grid>
                <Grid item xs={12} md={6} lg={4} className='grid-item'>
                    <Link to='/mythology-of-the-suburb' className='gallery-link'>
                        <div className='gallery-intro intro-five'>
                            <div className='overlay-title'>
                                <LanguageContext.Consumer>{(context) => {
                                    const { isPolish } = context;
                                    const title = isPolish ? " Mitologia przedmieścia" : "Mythology Of The Suburb"
                                    return (
                                        title
                                    )
                                 }}</LanguageContext.Consumer>  
                            </div>
                        </div>
                    </Link>  
                </Grid>
                <Grid item xs={12} md={6} lg={4} className='grid-item'>
                    <Link to='/new-paintings' className='gallery-link'>
                        <div className='gallery-intro intro-six'>
                            <div className='overlay-title'>
                            <LanguageContext.Consumer>{(context) => {
                                    const { isPolish } = context;
                                    const title = isPolish ? "Nowe Obrazy" : "New Paintings"
                                    return (
                                        title
                                    )
                                 }}</LanguageContext.Consumer>  
                            </div>
                        </div>
                    </Link> 
                </Grid>
            </Grid> 
            </Fade>
        </div>
    )
}

export default Gallery;
