import React from 'react';
// import './Navbar.css';
import Fade from 'react-reveal/Fade';
import { makeStyles } from "@material-ui/core";
// import Hamburger from "../Hamburger/Hamburger";
import Menu from "../Navbar/Menu";




const useStyles = makeStyles({
    navbar: {
        width: "100vw",
        height: "7vh",
        display: "flex",
        justifyContent: "center",
        "@media (max-width: 700px)": {
        alignItems: "center",
        flexDirection: "column",
        height: "300px",
        }
    },

    // navList: {
    //     // textAlign: "center",
    // },

    // navBar: {
    //     display: "inline",
    //     margin: "1.9vw",
    //     textTransform: "uppercase",
    //     fontFamily: "Cinzel, serif",
    //     "@media (max-width: 700px)": {
    //         display: "flex",
    //         justifyContent: "center"
    //     }
    // },
 
    // navLink: {
    //     textDecoration: "none",
    //     color: "rgb(35, 34, 37)",
    //     fontSize: "1.3rem",
    //     cursor: "pointer",
    //     '&:hover': {
    //         textShadow: "2px 2px 8px rgb(179, 179, 146)"
    //     }
    // },

    // active: {
    // borderBottom: "0.05rem solid black",
    // fontWeight: "bold",
    // }
})



const Navbar = () => {

    // const [isOpen, setIsOpen] = useState(false);

    return (
        <Fade top>
        <div className={useStyles().navbar}>
        {/* <Hamburger onClick={ ()=> setIsOpen(!isOpen)} /> */}
            <Menu/>
        </div>
        </Fade>
    )
}

export default Navbar;




