import React from "react";
import BioNav from "../BioNav/BioNav";
import {Grid} from "@material-ui/core";
import Fade from "react-reveal/Fade";
import "../../App.css";
import BioFoto from "../BioFoto/BioFoto";
import { makeStyles } from "@material-ui/core";
import {LanguageContext} from "../../LanguageContext";
import BioExhibitionsPL from "./BioExhibitionsPL";
import BioExhibitionsEN from "./BioExhibitionsEN";



const useStyles = makeStyles({
  container: {
    display: "flex",
    padding: "1.5em", 
  },
  bioText: {
    fontFamily: "Marcellus', serif",
    fontSize: "1.1rem",
    color: "rgb(35, 34, 37)",
    margin: "auto",
    width: "100%",
  },
  bioFoto:{
    height: "65vh",
    width: "auto",
    boxShadow: "none",
    paddingTop: "0.2rem",
    margin: "auto",
    "@media (max-width: 700px)": {
      alignSelf: "center"
    }
  },
})

const BioExhibitions = () => {
  return (
    <>
    <Fade top>
      <BioNav/>
    </Fade>
    <Fade>
      <Grid container spacing={4} className={useStyles().container} justify="center">
          <BioFoto />
          <Grid item xs={12} sm={12} md={6} >
              <div className={useStyles().bioText}>
                <LanguageContext.Consumer>{(context) => {
                  const { isPolish } = context;
                  const exhibitions = isPolish ? <BioExhibitionsPL/> : <BioExhibitionsEN/>;
                    return (
                      exhibitions
                    )
                }}</LanguageContext.Consumer>                     
                          
              </div>
          </Grid>
      </Grid>
    </Fade>
      </>
  )
}

export default BioExhibitions;
