import {BrowserRouter} from "react-router-dom";
import Divider from "./components/Divider/Divider";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import {Router} from "./components/Router/Router";
import LanguageContextProvider from "./LanguageContext";
import HttpsRedirect from 'react-https-redirect';


const App = () => {

  return (
    <>
   
   <HttpsRedirect>
      <BrowserRouter>  
        <LanguageContextProvider>
          <Header/>
          <Navbar/>
          <Divider/>
          <Router/>
          <Divider/>
          <Footer/> 
        </LanguageContextProvider>
      </BrowserRouter>  
    </HttpsRedirect>

  </>
  );
}

export default App;
