import React from 'react';
import './Footer.css';
// import { Link } from 'react-router-dom';
// import Line from './Line';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
// import {IconContext} from "react-icons";



const Footer = () => {
    return(
        <div className='footer'>   
            <div></div>
            <div className='media'>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Kolpanart-104860987763030" className="contactLink">
                    <FaFacebook className='media-icon'/>
                </a>   
                {/* <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Kolpanart-104860987763030" className="contactLink">
                    <FaInstagram className='media-icon'/>
                </a> */}
            </div>
        </div>
    )
}

export default Footer;