import React, {Component, createContext}  from 'react';

export const LanguageContext = createContext();

class LanguageContextProvider extends Component {
    
    state = {
        isPolish: true,
    }

    toggleLanguage = () => {
        this.setState({ isPolish: !this.state.isPolish })    
    }
    
    render() {
        return (
            <LanguageContext.Provider value={{...this.state, toggleLanguage: this.toggleLanguage}}>
                {this.props.children}
            </LanguageContext.Provider>
        );
    }
}


export default LanguageContextProvider;