import {Grid} from "@material-ui/core";
import React from "react";
import Fade from "react-reveal/Fade";
import "../App.css";
import BioNav from "../components/BioNav/BioNav";
// import foto from "../img/MK.jpg";
import BioFoto from "../components/BioFoto/BioFoto";
import { makeStyles } from "@material-ui/core";
import {LanguageContext} from "../LanguageContext";
import BioTextPL from "../components/BioText/BioTextPL"
import BioTextEN from "../components/BioText/BiotextEN"



const useStyles = makeStyles({
  container: {
    display: "flex",
    /* align-items: center; */
    justifyContent: "center",
    padding: "1.5em", 
  },
  bioText: {
    fontFamily: "Marcellus', serif",
    fontSize: "1.1rem",
    color: "rgb(35, 34, 37)",
  },
  bioPara: {
    marginBottom: "1rem",
    textIndent: "20px",
    textAlign: "justify",
    lineHeight: "1.8rem",
  },

})

const Biography = () => {

  return (
    <>
      <Fade top>
        <BioNav/>
      </Fade>
      <Fade>
      <Grid container spacing={2} className={useStyles().container}>
        {/* <Grid item xs={11} md={6}> */}
          <BioFoto />
        
                    <Grid item xs={12} sm={12} md={6}>
                        <div className={useStyles().bioText}>
                     
                            <LanguageContext.Consumer>{(context) => {
                              const { isPolish } = context;
                              const bio = isPolish ? <BioTextPL/> : <BioTextEN/>;
                              return (
                                  bio
                              )
                            }}</LanguageContext.Consumer>
                            
                            {/* <p className={useStyles().bioPara}>
                               Ur. 19 listopada 1963 r. w Krakowie. Jego ojciec, Andrzej Kołpanowicz był artystą malarzem, jednym z prekursorów polskiej szkoły realizmu fantastycznego. Matka jest także artystką malarką, mieszka w Etiopii, tworzy współczesne malarstwo sakralne inspirowane ikoną koptyjską. W latach 1982-1987 Marcin Kołpanowicz studiował na Wydziale Malarstwa Akademii Sztuk Pięknych w Krakowie. Dyplom z malarstwa obronił w 1987 r. w pracowni prof. Stanisława Rodzińskiego i Zbyluta Grzywacza. Od tego czasu zorganizował ponad 30 wystaw indywidualnych w Polsce, Niemczech, Francji, Austrii i USA oraz brał udział w wielu wystawach zbiorowych, w tym w najważniejszych międzynarodowych pokazach realizmu fantastycznego oraz w Salonie Paryskim w 2017 r.
                            </p>
                            <p className={useStyles().bioPara}>   
                               Marcin Kołpanowicz uprawia metaforyczne malarstwo olejne i pastelowe, w którym łączy nieskrępowaną wyobraźnię, poetycką metaforę i paradoksalne poczucie humoru. Jest również twórcą portretów, plakatów i ilustracji książkowych.
                            </p>   
                            <p className={useStyles().bioPara}>
                               Obrazy Kołpanowicza “zagrały” w serialu TVP Siedlisko w reż. Janusza Majewskiego (Anna Dymna malowała “latający parowóz” Kołpanowicza), pojawiły się na okładkach książek (m.in. “Kielich” Waldemara Łysiaka, „Brulion paryski” Arkadiusza Pacholskiego, „Festung Europa” Moniki Bartoszewicz, „Znak miłości” Marka Sołtysika, „Notatki i sny” Elżbiety Zechenter-Spławińskiej  oraz liczne tomiki poetyckich). Obrazy Kołpanowicza były także zamieszczane w podręcznikach gimnazjalnych i licealnych, wykorzystane przy pytaniach maturalnych w 2018 r. i na plakatach University of California w Berkeley. Artysta zrealizował Drogę Krzyżową w kościele św. Katarzyny Aleksandryjskiej w Tenczynku k. Krakowa, obraz beatyfikacyjny siostry Marty Wieckiej w Śniatyniu (Ukraina) oraz obraz ołtarzowy i fresk w Centrum Św. Jana Pawła II w „Nie lękajcie się” w Krakowie-Łagiewnikach.
                            </p>
                            <p className={useStyles().bioPara}>   
                               W 2019 roku został wyróżniony odznaką honorową „Zasłużony dla Kultury Polskiej”, w 2020 roku Orderem św. Stanislawa i Medalem Roku św. Jana Pawła.
                            </p>
                            <p className={useStyles().bioPara}>   
                               Obok malarstwa Marcin Kołpanowicz zajmuje się działalnością literacką i publicystyczną: od 2012 r. pisze felietony i recenzje do kwartalnika „Artysta i Sztuka” oraz teksty podróżnicze do miesięcznika „Poznaj Świat”; publikował także recenzje i teksty o sztuce w kwartalniku „Bliza”. W 2016 roku wydał album „Marcin Kołpanowicz - Malarstwo/Painting”.
                            </p> */}

                        </div>
                    </Grid>
            </Grid>
          </Fade>
        </>
    )
}

export default Biography;
