import React from 'react';
import { makeStyles } from "@material-ui/core";
// import DividerMini from '../components/DividerMini/DividerMini';


const useStyles = makeStyles({
    post: {
        width: "65%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "@media (max-width: 900px)": {
            width: "100%",
        }, 
    },
    postHeader: {
        width: "100%",
        fontWeight: "bold",
        fontSize: "1em",
        display: "flex",
        justifyContent: "space-between",
        margin: "1em",
        marginTop: "5em",
        paddingLeft: "1em",
        paddingRight: "1em",
    },
    postTitle: {
        fontSize: "1.1em",
    },
    postDate: {
        fontSize: "0.9em",
    },
    postText: {
        padding: "1em",
        textAlign: "justify",
        textIndent: "20px",
    },
    postPhoto: {
        height: "40vh",
        width: "auto",
        float: "left",
        marginTop: "0.5rem",
        marginRight: "2rem",
        marginBottom: "1rem",
        "@media (max-width: 800px)": {
            width: "90vw",
            height: "auto",
            margin: "auto",
        },
    },
})


const BlogPostEN = () => {    
    return (
        <div className={useStyles().post}>
                <div className={useStyles().postHeader}>
                    <div className={useStyles().postTitle}>Tha art in the times of plague</div>
                    <div className={useStyles().postDate}>July 29, 2021</div>
                </div>
                <div className={useStyles().postText}>
                    <p>
                        Jest już najnowszy numer kwartalnika "Artysta i Sztuka", a w nim mój felieton "Sztuka w czasach zarazy". Załączam darmową próbkę: 
                    </p>
                    <br/>
                    <p>"W naszym pełnym inwencji narodzie nigdy na szczęście nie brakowało pomysłów na obchodzenie przepisów. Tak stało się i tym razem. Oto podczas lockdownu klienci restauracji zamienili się w ich pracowników: byli mianowicie zatrudniani przez restauratorów na umowę o dzieło jako testerzy smaku, którzy po konsumpcji otrzymują wynagrodzenie w wysokości 1 złotego – można by rzec: napiwek à rebours. Miłośników sportów wodnych Polski Związek Pływacki powołał do kadry narodowej, dzięki czemu mogli znów regularnie korzystać z zamkniętych dotąd basenów. Z kolei siłownie zamieniono w trybie pilnym na świątynie, w których takie na przykład wyciskanie sztangi stało się praktyką religijną nowo założonego Kościoła Zdrowego Ciała. (...)
                    </p>
                    <p>
                        A dlaczego, u licha, przez kilka miesięcy zamknięte były muzea i galerie? Przecież tam i tak nikt nie chodził, a potencjalnym, choć mało prawdopodobnym zagęszczeniom publiczności można by łatwo zapobiec, organizując chociażby limitowaną sprzedaż biletów przez internet. W ten sposób podczas lockdownu galerie mogłyby − niechby i na ograniczoną skalę − prowadzić swą działalność, a być może zyskałyby nawet kilku dodatkowych zwiedzających, bo wybrałyby się tam z braku lepszego zajęcia osoby, które dotąd wystawy omijały szerokim łukiem. Galerie i muzea zdecydowano się otworzyć dopiero 1 lutego 2021, a i to „na podstawie bardzo wnikliwych ekspertyz i badań”, i tylko na próbę. Minister Kultury, Dziedzictwa Narodowego i Sportu, profesor Piotr Gliński, zauważył wówczas z odkrywczą miną, że „obiekty kulturalne są bezpieczniejsze niż siłownie i restauracje”. Może i są, choć moim zdaniem nie wszystkie, bo na pewno zagrożenia zdrowia psychicznego mniejsze jest na siłowni czy w restauracji niż w takim na przykład Mocaku lub innych centrach sztuki nowoczesnej. Ale to odrębny temat.
                        O ile na branżę muzyczną wirus sprowadził bessę, o tyle w tym samym okresie branża plastyczna przeżywała hossę. I to zarówno, jeśli chodzi o czynne uprawianie twórczości, jak i jej rynkowe konsekwencje. "Dla artysty bycie normalnym to katastrofa" − mówi stara mądrość, więc też malarze są często tak asocjalnymi jednostkami, że czas lock-downu nie różnił się dla nich (właściwie powinienem napisać: dla nas) prawie niczym od zwykłego trybu funkcjonowania. I tak siedzimy dniami i nocami zamknięci w swoich pracowniach, a w przymusowym lockdownie rośnie nam co najwyżej wydajność z hektara, choćby dzięki mniejszej ilości odrywających od malowania pokus  (czytaj:  spotkań towarzyskich, plenerów czy podróży). Głupio to powiedzieć, ale wreszcie mieliśmy idealne warunki do pracy! (Znajomy profesor ASP podzielił się ze mną obserwacją, że w czasie zdalnego studiowania jego uczniowie namalowali w domu o wiele lepsze obrazy, niż miało to miejsce w trakcie regularnych zajęć na uczelni)".
                    </p>
                    {/* <DividerMini /> */}
                </div>
        </div>           
    )
};

export default BlogPostEN;