// Invisible cities
import image2 from "../img/niewidzialne_miasta/aeropolis_max.jpg";
import image1 from "../img/niewidzialne_miasta/brama_niebios_max.jpg";
import image3 from "../img/niewidzialne_miasta/eurobabilon_max.jpg";
import image5 from "../img/niewidzialne_miasta/metropolis_max.jpg";
import image10 from "../img/niewidzialne_miasta/orien_express_max.jpg";
import image7 from "../img/niewidzialne_miasta/rhinoceropolis_max.jpg";
import image4 from "../img/niewidzialne_miasta/sen_o_miescie_max.jpg";
import image9 from "../img/niewidzialne_miasta/tornadopolis_max.jpg";
import image8 from "../img/niewidzialne_miasta/ulica_niesmiertelnych_max.jpg";
import image6 from "../img/niewidzialne_miasta/vulcanopolis_max.jpg";
// Mythology of the suburb:
import image11 from "../img/mitologia_przedmiescia/Cloud_Sculptor.jpg";
import image12 from "../img/mitologia_przedmiescia/dziecinstwo.jpg";
import image13 from "../img/mitologia_przedmiescia/Miejsce_przeznaczenia.jpg";
import image14 from "../img/mitologia_przedmiescia/Miss_Robinson.jpg";
import image15 from "../img/mitologia_przedmiescia/Popołudnie_Fauna.jpg";
import image16 from "../img/mitologia_przedmiescia/Sen_we_śnie.jpg";
import image17 from "../img/mitologia_przedmiescia/Ulica_bez_nazwy.jpg";
import image18 from "../img/mitologia_przedmiescia/wilk_stepowy.jpg";
// Cathedral:
import image19 from "../img/katedra/bazylika.jpg"; 
import image20 from "../img/katedra/katedra.jpg"; 
import image21 from "../img/katedra/katedra_v.jpg"; 
import image22 from "../img/katedra/katedra_w_Orcival.jpg"; 
import image23 from "../img/katedra/katedra_zimy.jpg"; 
import image24 from "../img/katedra/swiatynia.jpg"; 
import image25 from "../img/katedra/zatopiona_katedra.jpg"; 
import image26 from "../img/katedra/zimowa_katedra.jpg"; 
//Theatre of the world:
import image27 from "../img/teatr_swiata/teatr_swiata.jpg";
import image28 from "../img/teatr_swiata/czlowiek_ktorego_serce_bylo_w_gorach.jpg";
import image29 from "../img/teatr_swiata/DESCRIPTIO_MUNDI.jpg";
import image30 from "../img/teatr_swiata/flotapowietrzna.jpg";
import image31 from "../img/teatr_swiata/improwizacja.jpg";
import image32 from "../img/teatr_swiata/Kraina_szczesliwego_dziecinstwa.jpg";
import image33 from "../img/teatr_swiata/LE_QUATTRO_STAGIONI.jpg";
import image34 from "../img/teatr_swiata/LINIA_ZYCIA.jpg";
import image35 from "../img/teatr_swiata/SCHODY_DO_NIEBA.jpg";
import thea10 from "../img/teatr_swiata/1_wiosna.jpg";
import thea11 from "../img/teatr_swiata/2 _lato.jpg";
import thea12 from "../img/teatr_swiata/3_jesien.jpg";
import thea13 from "../img/teatr_swiata/4_zima.jpg";
//Travellers room:
import image36 from "../img/pokoj_podroznika/Pokoj_Podroznika.jpg";
import image37 from "../img/pokoj_podroznika/gedania_phantastica.jpg";
import image38 from "../img/pokoj_podroznika/Pokoj_kazimierza_nowaka.jpg";
import image39 from "../img/pokoj_podroznika/sen_podrozniczki.jpg";
import image40 from "../img/pokoj_podroznika/pokoj_muzyczny.jpg";
import image41 from "../img/pokoj_podroznika/Pokoj_podrozniczki.jpg";
import image42 from "../img/pokoj_podroznika/powrot_z_wyprawy_zamorskiej.jpg";
import image43 from "../img/pokoj_podroznika/pracownia_geografa.jpg";
//New paintings:
import image44 from "../img/nowe_obrazy/nowe_jeruzalem.jpg";
import image45 from "../img/nowe_obrazy/orien_express.jpg";




// News - posts:

import imgNews1 from "../img/aktualnosci/Sen_nocy_letniej.jpg";
import imgNews2 from "../img/aktualnosci/Cztery_pory_roku.jpg";
import imgNews3 from "../img/aktualnosci/Dziewczyna_z_parasolem.jpg";


import imgNews4 from "../img/aktualnosci/Magical0.jpg";
import imgNews5 from "../img/aktualnosci/Magical1.jpg";
import imgNews6 from "../img/aktualnosci/Magical2.jpg";
import imgNews7 from "../img/aktualnosci/Magical3.jpg";
import imgNews8 from "../img/aktualnosci/Magical4.jpg";
import imgNews9 from "../img/aktualnosci/Magical5.jpg";
import imgNews11 from "../img/aktualnosci/Magical7.jpg";
import imgNews12 from "../img/aktualnosci/Magical8.jpg";
import imgNews13 from "../img/aktualnosci/Magical9.jpg";
import imgNews10 from "../img/aktualnosci/Magical10.jpg";
import imgNews14 from "../img/aktualnosci/Magical6.jpg";

import imgNews15 from "../img/aktualnosci/Podroz_do_zrodel_czasu.jpg"

import imgNews16 from "../img/aktualnosci/Cztery_pory_roku_olej.jpg";


// import MK from "../img/MK.jpg"
// const MKfoto = {
//   src: MK,
//   alt: "Marcin Kołpanowicz"
// }


// BLOG - posts:

import imgBlog1 from "../img/blog/szum_wokol_malarstwa.jpg";
import imgBlog2 from "../img/blog/kalendarz_z_bykiem.jpg";
// import imgBlog3 from "../img/blog/xxx"
// import imgBlog4 from "../img/blog/xxx"








// Invisible cities
const INV1 = {
  src: image1,
  title: "Brama Niebios",
  details: "2006 r., olej na płótnie, 100 x 100 cm",
  alt: "Brama Niebios",
  titleEN: "The Gate of Heaven",
  detailsEN: "2006, oil on canvas, 39,4 x 39,4 inches",
  altEN: "The Gate of Heaven",
};
const INV2 = {
  src: image2,
  title: "Aeropolis",
  details: "2020 r., pastel, 60 x 105 cm",
  alt: "Aeropolis",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const INV3 = {
  src: image3,
  title: "Eurobabilon",
  details: "2012 r., olej na płótnie, 100 x 70 cm",
  alt: "Eurobabilon",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const INV4 = {
  src: image4,
  title: "Sen o mieście",
  details: "2013 r., olej na płótnie, 100 x 100 cm",
  alt: "Sen o mieście",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const INV7 = {
  src: image5,
  title: "Metropolis",
  details: "2008 r., olej na płótnie, 100 x 100 cm",
  alt: "Metropolis",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const INV6 = {
  src: image6,
  title: "Vulcanopolis",
  details: "2013 r., olej na płótnie, 100 x 100 cm",
  alt: "Vulcanopolis",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const INV5 = {
  src: image7,
  title: "Rhinoceropolis",
  details: "2019 r., pastel, 70 x 90 cm",
  alt: "Rhinoceropolis",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const INV8 = {
  src: image8,
  title: "Ulica Nieśmiertelnych",
  details: "2006 r., olej na płótnie, 100 x 100 cm",
  alt: "Ulica Nieśmiertelnych",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const INV9 = {
  src: image9,
  title: "Tornadopolis",
  details: "2008 r., olej na płótnie, 100 x 100 cm",
  columns: 4,
  alt: "Tornadopolis"
};
const INV10 = {
  src: image10,
  title: "Orient Express",
  details: "2021 r., olej na płótnie, 80 x 60 cm",
  alt: "Orient Express",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};


// Mythology of the suburb:
const MYT3 = {
  src: image11,
  title: "Cloud Sculptor",
  details: "2009 r., pastel, 70 x 90 cm",
  alt: "Cloud Sculptor",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const MYT2 = {
  src: image12,
  title: "Dzieciństwo",
  details: "2013 r., olej na płótnie, 70 x 70 cm",
  alt: "Dzieciństwo",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const MYT6 = {
  src: image13,
  title: "Miejsce przeznaczenia",
  details: "",  ///BRAK
  alt: "Miejsce przeznaczenia",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const MYT1 = {
  src: image14,
  title: "Miss Robinson",
  details: "2019 r., olej na płótnie, 80 x 80 cm",
  alt: "Miss Robinson",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const MYT4 = {
  src: image15,
  title: "Popołudnie Fauna",
  details: "1990 r., olej na płycie, 100 x 100 cm",
  alt: "Popołudnie Fauna",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const MYT5 = {
  src: image16,
  title: "Sen we śnie",
  details: "1999 r., olej na płótnie, 65 x 65 cm",
  alt: "Sen we śnie",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const MYT7 = {
  src: image17,
  title: "Ulica bez nazwy",
  details: "1999 r., olej na płótnie, 80 x 80 cm",
  alt: "Ulica bez nazwy",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const MYT8 = {
  src: image18,
  title: "Wilk stepowy",
  details: "1987 r., olej na płycie, 60 x 90 cm",
  alt: "Wilk stepowy",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};


// Cathedral
const CAT4= {
  src: image19,
  title: "Bazylika",
  details: "2010 r., olej na płótnie, 70 x 100 cm",
  alt: "Bazylika",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const CAT6= {
  src: image20,
  title: "Katedra",
  details: "2010 r., olej na płótnie, 100 x 80 cm",
  alt: "Katedra",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
// const CAT3= {
//   src: image21,
//   title: "Katedra V",
//   details: "??? :)",  //BRAK
//   columns: 4,
//   alt: "Katedra V"
// };
const CAT5= {
  src: image22,
  title: "Katedra w Orcival",
  details: "2009 r., olej na płótnie, 70 x 100 cm",
  alt: "Katedra w Orcival",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const CAT1= {
  src: image23,
  title: "Katedra Zimy",
  details: "2020 r., olej na płótnie, 80 x 60 cm",
  alt: "Katedra Zimy",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const CAT7= {
  src: image24,
  title: "Świątynia",
  details: "2012 r., olej na płótnie, 70 x 100 cm",
  alt: "Świątynia",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const CAT2= {
  src: image25,
  title: "Zatopiona Katedra",
  details: "2014 r., pastel, 100 x 75 cm",
  alt: "Zatopiona Katedra",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const CAT8= {
  src: image26,
  title: "Zimowa Katedra",
  details: "2013 r., pastel, 100 x 70 cm",
  alt: "Zimowa Katedra",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};

//Theatre of the world:
const THEA4= {
  src: image27,
  title: "Teatr Świata",
  details: "2019 r., olej na płótnie, 80 x 80 cm",
  alt: "Teatr Świata",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const THEA2= {
  src: image28,
  title: "Człowiek, którego serce było w górach",
  details: "1998 r., olej na płótnie, 100 x 70 cm",
  alt: "Człowiek, którego serce było w górach",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const THEA5= {
  src: image29,
  title: "Descriptio Mundi",
  details: "1999 r., olej na płótnie, 70 x 50 cm",
  alt: "Descriptio Mundi",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const THEA8= {
  src: image30,
  title: "Flota Powietrzna",
  details: "2015 r., olej na płótnie, 60 x 75 cm",
  alt: "Flota Powietrzna",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const THEA3= {
  src: image31,
  title: "Improwizacja",
  details: "2020 r., olej na płótnie, 90 x 80 cm",
  alt: "Improwizacja",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const THEA6= {
  src: image32,
  title: "Kraina szczęśliwego dzieciństwa",
  details: "2009 r., olej na płótnie, 50 x 65 cm",
  alt: "Kraina szczęśliwego dzieciństwa",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const THEA9= {
  src: image33,
  title: "Le Quattro Stagioni",
  details: "2012 r., olej na płótnie, średnica: 80cm",
  alt: "Le Quattro Stagioni",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const THEA7 = {
  src: image34,
  title: "Linia Życia",
  details: "2002 r., olej na płótnie, 80 x 80 cm",
  alt: "Linia Życia",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const THEA1 = {
  src: image35,
  title: "Schody Do Nieba",
  details: "1999 r., olej na płótnie, 100 x 100 cm",
  alt: "Schody Do Nieba",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};

const THEA10 = {
  src: thea10,
  title: "Tańczące Anioły / Wiosna",
  details: "2021 r., olej na płótnie, 90 x 70 cm",
  alt: "Wiosna",
  titleEN: "Dancing angels / Spring",
  detailsEN: "",
  altEN: "Spring",
}

const THEA11 = {
  src: thea11,
  title: "Tańczące Anioły / Lato",
  details: "2021 r., olej na płótnie, 90 x 70 cm",
  alt: "Lato",
  titleEN: "Dancing angels / Summer",
  detailsEN: "",
  altEN: "Summer",
}
const THEA12 = {
  src: thea12,
  title: "Tańczące Anioły / Jesień",
  details: "2021 r., olej na płótnie, 90 x 70 cm",
  alt: "Jesień",
  titleEN: "Dancing angels / Autumn",
  detailsEN: "",
  altEN: "Autumn",
}
const THEA13 = {
  src: thea13,
  title: "Tańczące Anioły / Zima",
  details: "2021 r., olej na płótnie, 90 x 70 cm",
  alt: "Zima",
  titleEN: "Dancing angels / Winter",
  detailsEN: "",
  altEN: "Winter",
}

//Travellers Room:
const TRAV2= {
  src: image36,
  title: "Pokój Podróżnika",
  details: "", //BRAK
  alt: "Pokój Podróżnika",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const TRAV8= {
  src: image37,
  title: "Gedania Phantastica",
  details: "2019 r., olej na płótnie, 140 x 180 cm",
  alt: "Gedania Phantastica",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const TRAV1= {
  src: image38,
  title: "Pokoj Kazimierza Nowaka",
  details: "2010 r., pastel, 65 x 100 cm",
  alt: "Pokoj Kazimierza Nowaka",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const TRAV3= {
  src: image39,
  title: "Sen Podróżniczki",
  details: "2019 r., olej na płótnie, 80 x 100 cm",
  alt: "Sen Podróżniczki",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const TRAV7= {
  src: image40,
  title: "Pokój Muzyczny",
  details: "2009 r., pastel, 60 x 90 cm",
  alt: "Pokój Muzyczny",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const TRAV4= {
  src: image41,
  title: "Pokój Podróżniczki",
  details: "2020 r., olej na płótnie",  //BRAK
  alt: "Pokój Podróżniczki",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const TRAV5= {
  src: image42,
  title: "Powrot z Wyprawy Zamorskiej",
  details: "2003 r., olej na płótnie, 70 x 100 cm",  //na płótnie?
  alt: "Powrot z Wyprawy Zamorskiej",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const TRAV6= {
  src: image43,
  title: "Pracownia Geografa",
  details: "2003 r., olej na płótnie, 100 x 140 cm",
  alt: "Pracownia Geografa",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};

//New Paintings:
const NEW1= {
  src: image44,
  title: "Nowe Jeruzalem",
  details: "",                //BRAK
  alt: "Nowe Jeruzalem",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};
const NEW2= {
  src: image45,
  title: "Orent Express IV",
  details: "olej na płótnie, 80 x 60 cm",   //BRAK
  alt: "Orent Express IV",
  titleEN: "",
  detailsEN: "",
  altEN: "",
};


 



// News - posts:

const POST1 = {
  src: imgNews1,
  title: "Sen nocy letniej",
  details: "79 x 70 cm",
  alt: "Sen nocy letniej",
  titleEN: "",
  detailsEN: "",
  altEN: "",
}

const POST2 = {
  src: imgNews2,
  title: "Cztery Pory Roku",
  details: "",
  alt: "Cztery Pory Roku",
  titleEN: "Dance of the seasons tetraptych",
  detailsEN: "",
  altEN: "",
}
const POST3 = {
  src: imgNews3,
  title: "Dziewczyna z parasolem",
  details: "",
  alt: "Dziewczyna z parasolem",
  titleEN: "Blue umbrella",
  detailsEN: "",
  altEN: "",
}
const POST4 = {
  src: imgNews4,
  title: "Wernisaż Magical Dreams",
  details: "",
  alt: "Wernisaż Magical Dreams",
  titleEN: "Magical Dreams vernissage",
  detailsEN: "",
  altEN: "",
}

const POST4_1 = {
  src: imgNews5,
  title: "Wernisaż Magical Dreams",
  details: "",
  alt: "Wernisaż Magical Dreams",
  titleEN: "Magical Dreams vernissage",
  detailsEN: "",
  altEN: "",
}
const POST4_2 = {
  src: imgNews6,
  title: "Wernisaż Magical Dreams",
  details: "",
  alt: "Wernisaż Magical Dreams",
  titleEN: "Magical Dreams vernissage",
  detailsEN: "",
  altEN: "",
}
const POST4_3 = {
  src: imgNews7,
  title: "Wernisaż Magical Dreams",
  details: "",
  alt: "Wernisaż Magical Dreams",
  titleEN: "Magical Dreams vernissage",
  detailsEN: "",
  altEN: "",
}
const POST4_4 = {
  src: imgNews8,
  title: "Wernisaż Magical Dreams",
  details: "",
  alt: "Wernisaż Magical Dreams",
  titleEN: "Magical Dreams vernissage",
  detailsEN: "",
  altEN: "",
}
const POST4_5 = {
  src: imgNews9,
  title: "Wernisaż Magical Dreams",
  details: "",
  alt: "Wernisaż Magical Dreams",
  titleEN: "Magical Dreams vernissage",
  detailsEN: "",
  altEN: "",
}
const POST4_6 = {
  src: imgNews10,
  title: "Wernisaż Magical Dreams",
  details: "",
  alt: "Wernisaż Magical Dreams",
  titleEN: "Magical Dreams vernissage",
  detailsEN: "",
  altEN: "",
}
const POST4_7 = {
  src: imgNews11,
  title: "Wernisaż Magical Dreams",
  details: "",
  alt: "Wernisaż Magical Dreams",
  titleEN: "Magical Dreams vernissage",
  detailsEN: "",
  altEN: "",
}
const POST4_8 = {
  src: imgNews12,
  title: "Wernisaż Magical Dreams",
  details: "",
  alt: "Wernisaż Magical Dreams",
  titleEN: "Magical Dreams vernissage",
  detailsEN: "",
  altEN: "",
}
const POST4_9 = {
  src: imgNews13,
  title: "Wernisaż Magical Dreams",
  details: "",
  alt: "Wernisaż Magical Dreams",
  titleEN: "Magical Dreams vernissage",
  detailsEN: "",
  altEN: "",
}
const POST4_10 = {
  src: imgNews14,
  title: "Wernisaż Magical Dreams",
  details: "",
  alt: "Wernisaż Magical Dreams",
  titleEN: "Magical Dreams vernissage",
  detailsEN: "",
  altEN: "",
}

const POST5 = {
  src: imgNews15,
  title: "Podróż do źródeł czasu",
  details: "",
  alt: "Podróż do źródeł czasu",
  titleEN: "",
  detailsEN: "",
  altEN: "",
}

const POST6 = {
  src: imgNews16,
  title: "Cztery pory roku",
  details: "",
  alt: "Cztery pory roku",
  titleEN: "",
  detailsEN: "",
  altEN: "",
}





// BLOG - posts:

const BLOG1 = {
  src: imgBlog1,
  title: "Łowca chmur",
  details: "",
  alt: "Łowca chmur",
  titleEN: "",
  detailsEN: "",
  altEN: "",
}

const BLOG2 = {
  src: imgBlog2,
  title: "Kalendarz - Cztery Pory Roku",
  details: "",
  alt: "Kalendarz - Cztery Pory Roku",
  titleEN: "",
  detailsEN: "",
  altEN: "",
}




export const INVimages = [INV1, INV2, INV3, INV4, INV5, INV6, INV7, INV8, INV9, INV10];
export const MYTimages = [MYT1, MYT2, MYT3, MYT4, MYT5, MYT6, MYT7, MYT8];
export const CATimages = [CAT1, CAT2, CAT5, CAT6, CAT7, CAT8, CAT4];

export const THEAimages = [THEA10, THEA11, THEA12, THEA13, THEA1, THEA2, THEA3, THEA4, THEA5, THEA6, THEA7, THEA8, THEA9];
export const TRAVimages = [TRAV1, TRAV2, TRAV3, TRAV4, TRAV5, TRAV6, TRAV7, TRAV8];
export const NEWimages = [NEW1, NEW2];


export const POSTimages =[POST1, POST2, POST3, POST4, POST4_1, POST4_2, POST4_3, POST4_4, POST4_5, POST4_6, POST4_7, POST4_8, POST4_9, POST4_10, POST5, POST6];

export const BLOGimages =[BLOG1, BLOG2];