import React from "react";
import {NavLink} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import {LanguageContext} from "../../LanguageContext";



const useStyles = makeStyles({
    bio: {
        width: "100vw",
        fontFamily: "Cinzel, serif",
        fontSize: "1.2rem",
    },
    bioList: {
        textAlign: "center",
    },
    bioOption: {
        display: "inline",
        textTransform: "uppercase",
        margin: "7vw",   
        '&:hover': {
             textShadow: "2px 2px 8px rgb(179, 179, 146)",
        },     
        "@media (max-width: 700px)": {
            margin: "2vw",
        }
    },
    bioLink: {
        textDecoration: "none",
        color: "rgb(35, 34, 37)",
        lineHeight: "10vh",
        cursor: "pointer",
    },
    active: {
        borderBottom: "0.01rem solid rgb(35, 34, 37)",
    }
})   



const BioNav = () => {
    return (

      <div className={useStyles().bio}>
          <ul className={useStyles().bioList}>
              <li className={useStyles().bioOption}>
                  <NavLink to="/biography" className={useStyles().bioLink} activeClassName={useStyles().active}>
                  <LanguageContext.Consumer>{(context) => {
                        const { isPolish } = context;
                        const bio = isPolish ? "Życiorys" : "Biography"
                        return (
                            bio
                        )
                    }}</LanguageContext.Consumer>
                  </NavLink>
                </li>
                <li className={useStyles().bioOption}>    
                    <NavLink to='/exhibitions' className={useStyles().bioLink} activeClassName={useStyles().active}>
                    <LanguageContext.Consumer>{(context) => {
                        const { isPolish } = context;
                        const exhibitions = isPolish ? "Wystawy" : "Exhibitions";
                        return (
                            exhibitions
                        )
                    }}</LanguageContext.Consumer>
                    </NavLink>  
                </li>
                <li className={useStyles().bioOption}>    
                    <NavLink to='/reviews' className={useStyles().bioLink} activeClassName={useStyles().active}>
                    <LanguageContext.Consumer>{(context) => {
                        const { isPolish } = context;
                        const reviews = isPolish ? "Recenzje" : "Reviews";
                        return (
                            reviews
                        )
                    }}</LanguageContext.Consumer>
                    </NavLink>  
                </li>
            </ul>
        </div>

    )
}

export default BioNav;
