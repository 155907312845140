import React from 'react';
import { LanguageContext } from "./LanguageContext";
import {makeStyles} from "@material-ui/core";



const useStyles = makeStyles({
    toggleButton: {
        "@media (min-width: 700px)": {
            fontSize: "1.2em",
            position: "absolute",
            top: "25px",
            right: "25px",
            cursor: "pointer",
        },  
        display: "flex",
        justifyContent: "center",
        marginBottom: "1em",
        // color: "#c3c4c7",
        fontSize: "1.1rem",
        cursor: "pointer",
        '&:hover': {
            textShadow: "2px 2px 8px rgb(179, 179, 146)"
        }
    }
     
})

const LanguageToggle = () => {

        return (
            <div className={useStyles().toggleButton}>
            <LanguageContext.Consumer>{(context) => {
                const { toggleLanguage, isPolish } = context;
                return(
                    <p onClick={toggleLanguage}>
                        {isPolish ? "- EN -" : ".:: PL ::."}
                    </p>
                    
                )
            }}</LanguageContext.Consumer>
            </div> 
        );
}


export default LanguageToggle;