import React from 'react';
// import DividerMini from '../components/DividerMini/DividerMini';
import Fade from "react-reveal/Fade";
import {POSTimages} from "../components/images";
import { makeStyles } from "@material-ui/core";
import {LanguageContext} from "../LanguageContext";
import NewsPostPL from "./NewsPostPL";
import NewsPostEN from "./NewsPostEN";


const useStyles = makeStyles({
    post: {
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "@media (max-width: 900px)": {
            width: "100%",
        },
    },
})



const News = () => {
    return (
        <div className='container container--blog'>
            <Fade>
            <div className={useStyles().post}>
                <LanguageContext.Consumer>{(context) => {
                        const { isPolish } = context;
                        const newsPost = isPolish ? <NewsPostPL/> : <NewsPostEN/>;
                        return (
                            newsPost
                        )
                }}</LanguageContext.Consumer>  
            </div>


            
            {/* <div className='post'>
                <div className='post__header'>
                    <div className='post__title'>Nulla interdum arcu ac tellus convallis</div>
                    <div className='post__date'>23 maja 2021 r.</div>
                </div>
                <div className='post__text'>
                <img src={image2} alt={image1.alt} className='post__photo'></img>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam congue mi sodales ex tincidunt pulvinar. Aliquam et diam vel libero cursus pretium ut vel quam. Mauris purus justo, maximus eget lobortis nec, fermentum et ex. Duis euismod lobortis elit sit amet fringilla. Curabitur id ipsum at metus ornare sollicitudin. Etiam malesuada est at erat aliquet pretium. Ut malesuada ultricies libero, sed blandit orci tempus et. Aliquam commodo tincidunt tellus in convallis. In eget varius dui, ac condimentum nisi.
                    Donec dapibus aliquet ex, in mollis metus finibus non. In vehicula odio vel consectetur bibendum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec tincidunt varius nisi non tempus. Fusce eu nisl molestie, dapibus libero quis, rutrum velit. Ut ex urna, aliquet et venenatis faucibus, pulvinar ac mauris. Phasellus consectetur enim vel mi egestas semper. Curabitur dignissim felis nec elit faucibus mattis. Sed rhoncus pulvinar nibh ac imperdiet. Sed vitae ipsum bibendum, congue mi vitae, viverra ex. Aenean a dignissim mauris, eget pellentesque odio. Curabitur et tempor augue. Pellentesque nec felis arcu.
                    Suspendisse gravida arcu libero, sit amet iaculis enim tempor gravida.  Nulla lectus justo, tristique ac risus in, auctor fermentum erat.
                    <DividerMini />
                </div>
            </div>
            
            <div className='post'>
                <div className='post__header'>
                <div className='post__title'>Nullam nulla lectus</div>
                    <div className='post__date'>20 maja 2021 r.</div>
                </div>
                <div className='post__text'>
                <img src={image1} alt={image1.alt} className='post__photo'></img>
                    Nulla lectus justo, tristique ac risus in, auctor fermentum erat. Praesent auctor, lacus eget bibendum bibendum, purus ipsum lobortis metus, sed ultricies tortor arcu at velit. Phasellus efficitur vel dolor eget maximus. Nunc sed tellus interdum, ullamcorper elit eu, malesuada dui. Maecenas sagittis ante quis lectus mattis, nec consectetur neque imperdiet. Fusce auctor consequat massa, a aliquet sapien faucibus eget. Vivamus arcu elit, consectetur vitae metus ac, bibendum eleifend eros. In tincidunt augue purus, vel pellentesque magna feugiat id. Mauris vulputate est ac auctor varius. Nam at rhoncus mauris. Morbi pretium in odio convallis sagittis. Morbi sagittis ac diam vel blandit. Nullam metus dolor, tristique eu vulputate non, gravida eu diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Cras non neque vitae diam mattis tristique in vitae lacus.
                    Suspendisse potenti. Sed sit amet lorem leo. Nulla interdum arcu ac tellus convallis, id sodales tortor iaculis. Etiam in eros tempus, rhoncus tellus semper, ornare neque. In lorem orci, efficitur non commodo sit amet, molestie id diam. Sed vestibulum mauris at risus consequat, a pharetra neque porttitor. Praesent tempor libero eget risus tincidunt, sit amet feugiat tellus pharetra.
                    Nulla luctus odio sed dolor viverra ornare. Nullam nulla lectus, tincidunt ac accumsan quis, aliquam eu arcu. Nullam ac venenatis mauris. Aliquam nisi nibh, aliquet ut enim non, sagittis aliquet lectus. Aliquam vestibulum tincidunt ex nec euismod. Cras pretium tempus quam a lacinia. Sed nec massa convallis, porta dolor quis, varius sem. Proin rutrum finibus diam at malesuada. Duis dolor massa, porttitor id risus eget, mollis pharetra nisl. Fusce consectetur efficitur aliquet. Sed ex metus, tempor a consequat ac, rhoncus interdum nisi. Mauris finibus justo quis lorem mattis, in tempor mauris pulvinar. Nam venenatis sollicitudin tortor ornare scelerisque. Mauris euismod convallis turpis. Nam vehicula libero et orci blandit accumsan.
                    Nulla lectus justo, tristique ac risus in, auctor fermentum erat. Praesent auctor, lacus eget bibendum bibendum, purus ipsum lobortis metus, sed ultricies tortor arcu at velit. Phasellus efficitur vel dolor eget maximus. Nunc sed tellus interdum, ullamcorper elit eu, malesuada dui. Maecenas sagittis ante quis lectus mattis, nec consectetur neque imperdiet. Fusce auctor consequat massa, a aliquet sapien faucibus eget. Vivamus arcu elit, consectetur vitae metus ac, bibendum eleifend eros. In tincidunt augue purus, vel pellentesque magna feugiat id. Mauris vulputate est ac auctor varius. Nam at rhoncus mauris. Morbi pretium in odio convallis sagittis. Morbi sagittis ac diam vel blandit. Nullam metus dolor, tristique eu vulputate non, gravida eu diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Cras non neque vitae diam mattis tristique in vitae lacus.
                    Suspendisse potenti. Sed sit amet lorem leo. Nulla interdum arcu ac tellus convallis, id sodales tortor iaculis. Etiam in eros tempus, rhoncus tellus semper, ornare neque. In lorem orci, efficitur non commodo sit amet, molestie id diam. Sed vestibulum mauris at risus consequat, a pharetra neque porttitor. Praesent tempor libero eget risus tincidunt, sit amet feugiat tellus pharetra.
                    Nulla luctus odio sed dolor viverra ornare.  Nulla lectus justo, tristique ac risus in, auctor fermentum erat. Nullam nulla lectus, tincidunt ac accumsan quis, aliquam eu arcu. Nullam ac venenatis mauris. Aliquam nisi nibh, aliquet ut enim non, sagittis aliquet lectus. Aliquam vestibulum tincidunt ex nec euismod. Cras pretium tempus quam a lacinia. Sed nec massa convallis, porta dolor quis, varius sem. Proin rutrum finibus diam at malesuada. Duis dolor massa, porttitor id risus eget, mollis pharetra nisl. Fusce consectetur efficitur aliquet. Sed ex metus, tempor a consequat ac, rhoncus interdum nisi. Mauris finibus justo quis lorem mattis, in tempor mauris pulvinar. Nam venenatis sollicitudin tortor ornare scelerisque. Mauris euismod convallis turpis. Nam vehicula libero et orci blandit accumsan.
                </div>
            </div> */}
        </Fade>
        </div>
    )
}

export default News;

