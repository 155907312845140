import React from 'react';
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles({
    bioList: {
        marginTop: "1.5rem",
        marginBottom: "1.5rem",
        marginLeft: "4rem",
        textAlign: "justify",
        lineHeight: "2rem",
        "@media (max-width: 700px)": {
          marginLeft: "2rem",
          width: "auto",
        },
        "@media (min-width: 700px) and (max-width: 900px)": {
          marginLeft: "15rem",
          width: "auto",
        },
      },
      bioListTitle: {
        fontWeight: "bold",
        fontSize: "1.2rem",
        marginLeft: "3.5rem",
      },
    })


const BioExhibitionsPL = () => {
    return (
        <>
             <p className={useStyles().bioListTitle}>Wybrane wystawy indywidualne:</p>
                          
                          <ul className={useStyles().bioList}>
                              <li>1987 – Galeria Inny Świat w Krakowie</li>
                              <li>1988 – Galeria NCK w Krakowie</li>
                              <li>1989 – Galeria Nr 4 w Krakowie</li>
                              <li>1992 – Inter Art Galerie Reich w Kolonii</li>
                              <li>Stara Galeria w Krakowie</li>
                              <li>1994 – Galerie Pont Neuf w Paryżu</li>
                              <li>1995 – Galeria Osorya w Krakowie</li>
                              <li>1996 – Inter Art Galerie Reich w Kolonii</li>
                              <li>Wiesinger Galerie w Linzu</li>
                              <li>1997 – Poly-Print Galerie w Wuppertalu</li>
                              <li>1999 – Galeria Krypta u Pijarów w Krakowie</li>
                              <li>2000 – Willa Decjusza w Krakowie</li>
                              <li>Galeria Palace w Krakowie</li>
                              <li>2001 – Galeria KOK w Krzeszowicach</li>
                              <li>Kunst und Kultur Zentrum w Aschau</li>
                              <li>2002 – Inter Art Galerie Reich w Kolonii</li>
                              <li>2005 – Vincentian Fathers w Nowym Jorku</li>
                              <li>2013 – Galeria Na Wprost w Iławie</li>
                              <li>2014 – Galeria Wieża Sztuki, Kielce </li>
                              <li>„Czterej jeźdźcy Surrealizmu: Beksiński, Jaśnikowski, Kołpanowicz, Olbiński”, II Targi Sztuki w Krakowie</li>
                              <li>2017 – „Fantastische Welten” Galeria De La Tour w Klagenfurcie </li>
                          </ul>
                         
                          <p className={useStyles().bioListTitle}>Ważniejsze wystawy zbiorowe:</p>
                         
                           <ul className={useStyles().bioList}>
                              <li>2003 – Międzynarodowe Biennale Ilustracji w Bratysławie</li>
                              <li> 2009 – „Dante – The Divine Commedy” w Saeby (Dania) I Viechtach (Niemcy)</li>
                              <li>„Surrealiści Polscy”, Galeria Miejska w Płocku</li>
                              <li>2010 – Międzynarodowy Salon Malarstwa INTER ART. w Kolonii</li>
                              <li>2011 – międzynarodowa wystawa realizmu magicznego “Light Fantastic”, Galeria Roosen w St. Truiden (Belgia)</li>
                              <li>“Podniebna flota”, galeria Focus w Warszawie</li>
                              <li>“Magical Dreams” (Szczyrk – Turyn – Kołobrzeg – Włocławek)</li>
                              <li>„Grupa Apellesa”, Millenium Hall w Rzeszowie</li>
                              <li>2012 – "Polen in Bayern" w Wolnzach (Niemcy)</li>
                              <li>2013 – międzynarodowa wystawa „Ambasadorowie Sztuki”, galeria DAP w Warszawie</li>
                              <li>2014 – wystawa SAFADORE – Spadkobiercy Dalego w Le Mont-Dore, Francja</li>
                              <li>2015 – „Magical Dreams” (Szczyrk, Wrocław, Warszawa, Viechtach)</li>
                              <li>2016 – „Ponad horyzontem zdarzeń”, galeria Quantum w Warszawie</li>
                              <li>2017 – „Art Capital ”, Grand Palais w Paryżu</li>
                              <li>2020 – „Odblask Tajemnicy” (Muzeum Diecezjalne w Kielcach, Muzeum „Ochorowiczówka” w Wiśle, Muzeum Monet i Medali Jana Pawła II w Częstochowie, Filharmona Kaszubska w Wejherowie, Pałac Rembielińskiego w Warszawie)</li>
                           </ul>
        </>
    )
}


export default BioExhibitionsPL;