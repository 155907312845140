import React from 'react';
import {POSTimages} from "../components/images";
import { makeStyles } from "@material-ui/core";
import DividerMini from '../components/DividerMini/DividerMini';



const useStyles = makeStyles({
    postBox: {
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        },  
    post: {
        width: "65%",
        height: "auto",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        display: "block",
        "@media (max-width: 900px)": {
            width: "100%",
        },  
    },
    postHeader: {
        width: "100%",
        fontWeight: "bold",
        fontSize: "1em",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "5em",
        paddingLeft: "1em",
        paddingRight: "1em",
    },
    postTitle: {
        fontSize: "1.1em",

    },
    postDate: {
        fontSize: "0.9em",
    },
    postText: {
        padding: "1em",
        textAlign: "justify",
        textIndent: "20px",
        "@media (max-width: 800px)": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
    },
    postPhoto: {
        height: "35vh",
        width: "auto",
        float: "left",
        marginTop: "0.5rem",
        marginRight: "2rem",
        marginBottom: "1rem",
        cursor: "pointer",
        "@media (max-width: 800px)": {
            width: "100%",
            height: "auto",
            marginRight: "0",
            // marginLeft: "4em",
        },
    },
    morePhotosBox: {
        padding: "1em",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        "@media (max-width: 800px)": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
    },
    morePhotos: {
        height: "10em",
        width: "auto",
        margin: "0.4em",
        cursor: "pointer",
        "@media (max-width: 800px)": {
            width: "80%",
            height: "auto",
        },
    },
    link: {
        color: "rgb(35, 34, 37)",
        textDecoration: "none",
        fontWeight: "bold",
        cursor: "pointer",
    },
})




const NewsPostPL = () => {
    return (
        <div className={useStyles().postBox}>

            <div className={useStyles().post}>
                {/* <DividerMini /> */}
                <div className={useStyles().postHeader}>
                    <div className={useStyles().postTitle}>Nowe obrazy w galerii Teatr Świata</div>
                    <div className={useStyles().postDate}>28&nbsp;stycznia&nbsp;2022&nbsp;r.</div>
                </div>
                <div className={useStyles().postText}>
                    <img src={POSTimages[15].src} alt={POSTimages[15].alt} className='post__photo'></img>
                    W galerii Teatr Świata pojawiły się nowe obarazy: cztery oleje na płótnie składające się na 
                    tetraptyk "Cztery pory roku".
                </div>          
            </div>

            <div className={useStyles().post}>
                <DividerMini />
                <div className={useStyles().postHeader}>
                    <div className={useStyles().postTitle}>Podróż do źródeł czasu</div>
                    <div className={useStyles().postDate}>14&nbsp;listopada&nbsp;2021&nbsp;r.</div>
                </div>
                <div className={useStyles().postText}>
                <img src={POSTimages[14].src} alt={POSTimages[14].alt} className='post__photo'></img>
                Nowy obraz w Sopockim Domu Aukcyjnym: „Podróż do źródeł czasu” (olej na płótnie, 70 x 120 cm). 
                Inspiracją do namalowania tego obrazu była powieść Alejo Carpentiera o tym samym tytule. 
                Jest ona uważana za jeden z pierwszych przykładów realizmu magicznego w literaturze. Bohater 
                powieści wyrusza z Francji na wyprawę do Ameryki Południowej (do Wenezueli? Kolumbii?) w 
                poszukiwaniu instrumentów muzycznych używanych przez prymitywne plemiona. Oddalając się od 
                zachodniej cywilizacji, przeżywa swoistą podróż w czasie: cofa się najpierw do wieku XIX, później 
                do okresu konkwistadorów, Średniowiecza, "epoki konia" i "epoki psa", wreszcie do epoki kamiennej 
                - gdy dociera do pierwotnych plemion Amazonii. Towarzyszką jego podróży jest Indianka imieniem 
                Rosario. Ją również umieściłem na obrazie. Są też inne szczegóły, nawiązujące do powieści 
                Carpentiera, ale malowidło jest samodzielną wizją, a nie dosłowną ilustracją książki. 
                Obraz będzie dostępny na aukcji w <a className={useStyles().link} href="https://www.sda.pl/aukcje,2,pl" target = "_ blank" rel="noreferrer">Sopockim Domu Aukcyjnym</a> 27 listopada.
                </div>          
            </div>


            <div className={useStyles().post}>
                <DividerMini />
                <div className={useStyles().postHeader}>
                    <div className={useStyles().postTitle}>Wernisaż Magical Dreams</div>
                    <div className={useStyles().postDate}>22&nbsp;września&nbsp;2021&nbsp;r.</div>
                </div>
                <div className={useStyles().postText}>
                    <img src={POSTimages[3].src} alt={POSTimages[3].alt} className={useStyles().postPhoto}></img>
                    W zeszłą sobotę, w galerii Bator. Wielkie święto realizmu fantastycznego, 
                    wernisaż Magical Dreams. Dzieła mistrzów wyobraźni z Ameryki, Meksyku, Armenii, 
                    Włoch, Francji, Austrii, Ukrainy, Rosji, Niemiec i Polski. Miłe spotkanie z 
                    artystkami i artystami, kolekcjonerami, galerzystami, przyjaciółmi. To nie 
                    przypadek, że ta wystawa odbywa się w Szczyrku, a więc w górach, a więc w 
                    przestrzeni wolności (także twórczej), nieskrępowanej imaginacji, swobodnych 
                    skojarzeń, wybujałej fantazji. W górach są latające smoki, gnomy w jaskiniach, 
                    jednorożce i chimery, gryfy, olbrzymy, śpiący rycerze, szklane szczyty, erupcje 
                    wulkanów, erupcje fantazji. „W górach jest wszystko co kocham” – jak pisał poeta.
                    W górach, jak wiadomo, nie przyjęło się prawo budowlane. I namordniki czyli tzw. 
                    maseczki też nie za bardzo. Ale za to po oficjalnym otwarciu, podczas bankietu 
                    goście przyjęli pierwszą dawkę. Cytrynówki oczywiście. A potem kolejne… I tak coś 
                    do trzeciej nad ranem.
                </div>           
                {/* <div className={useStyles().morePhotosBox}>
                <img src={POSTimages[4].src} alt={POSTimages[4].alt} className={useStyles().morePhotos}></img>
                <img src={POSTimages[5].src} alt={POSTimages[5].alt} className={useStyles().morePhotos}></img> 
                <img src={POSTimages[6].src} alt={POSTimages[6].alt} className={useStyles().morePhotos}></img>
                <img src={POSTimages[7].src} alt={POSTimages[7].alt} className={useStyles().morePhotos}></img>
                <img src={POSTimages[8].src} alt={POSTimages[8].alt} className={useStyles().morePhotos}></img>
                <img src={POSTimages[9].src} alt={POSTimages[9].alt} className={useStyles().morePhotos}></img>
                <img src={POSTimages[10].src} alt={POSTimages[10].alt} className={useStyles().morePhotos}></img>
                <img src={POSTimages[11].src} alt={POSTimages[11].alt} className={useStyles().morePhotos}></img>
                <img src={POSTimages[12].src} alt={POSTimages[12].alt} className={useStyles().morePhotos}></img>
                <img src={POSTimages[13].src} alt={POSTimages[13].alt} className={useStyles().morePhotos}></img>
                

                </div>  */}
            
            </div>
                
            <div className={useStyles().post}>
                <DividerMini />
                <div className={useStyles().postHeader}>
                    <div className={useStyles().postTitle}>Dziewczyna z parasolem</div>
                    <div className={useStyles().postDate}>1&nbsp;września&nbsp;2021&nbsp;r.</div>
                </div>
                <div className={useStyles().postText}>
                <img src={POSTimages[2].src} alt={POSTimages[2].alt} className='post__photo'></img>
                Na aukcji sztuki fantastycznej pojawił się mój pastel "Dziewczyna z parasolem". 
                Z rynku wtórnego, ale zawsze. :) Inspiracją była tajemnicza dziewczyna, którą spotkałem dwa lata temu w Rzymie, wczesnym rankiem, w okolicach Koloseum. Szła drugą stroną ulicy, okryta kocem, z niebieskim parasolem i reklamówką, w której miała, jak przypuszczam, cały swój dobytek. Szła jakby płynęła w powietrzu, zatopiona w  myślach, nieobecna. Ale wymieniliśmy uśmiechy. I tyle. Ale po powrocie przyszedł mi do głowy ten obraz. Dziewczyna wychodzi z wielkiego miasta, zostawia za sobą cywilizację. Trochę jak w tej piosence: "Oprócz błękitnego nieba nic mi więcej nie potrzeba"
                </div>          
            </div>


            <div className={useStyles().post}>
                <DividerMini />
                <div className={useStyles().postHeader}>
                    <div className={useStyles().postTitle}>Cztery Pory Roku</div>
                    <div className={useStyles().postDate}>1 września 2021&nbsp;r.</div>
                </div>
                <div className={useStyles().postText}>
                <img src={POSTimages[1].src} alt={POSTimages[1].alt} className='post__photo'></img>
                "Cztery pory roku" - mój nowy tetraptyk (czyli 4 pastele składające się na jedno dzieło), już dostarczony do Bator Art Gallery w Szczyrku na wystawę Magical Dreams 2021.
                </div>
            </div>
        

            <div className={useStyles().post}>
                <DividerMini />
                <div className={useStyles().postHeader}>
                    <div className={useStyles().postTitle}>Sen nocy letniej</div>
                    <div className={useStyles().postDate}>26 lipca 2021&nbsp;r.</div>
                </div>
                <div className={useStyles().postText}>
                <img src={POSTimages[0].src} alt={POSTimages[0].alt} className='post__photo'></img>
                Mój nowy pastel "Sen nocy letniej" (79 x 70 cm) będzie dostępny 6 sierpnia (piątek) na aukcji <a className={useStyles().link} href="https://artinfo.pl/katalogi-aukcyjne/sztuka-fantastyczna-surrealizm-i-realizm-magiczny-93275f95-a763-4f84-95e9-47742d506dd7?fbclid=IwAR30j6JKnIZniW6VkvTgTXWePOG7FteyVTt0ZedNOm0XdEbrsKspP6M1wpo" target="_blank" rel="noreferrer">Desa Unicum</a> w Grand Hotelu w Sopocie. Numer w katalogu aukcyjnym: 44.
                </div>
            </div>

        </div>
    )
};


export default NewsPostPL;






// const PostGalleryPhoto = ({image, index, show}) => {
//     return (
    
//         <img
//           key={image.src}
//           className={useStyles().morePhotos}
//           onClick={() => show(index)} 
//           src={image.src}
//           alt={image.alt} 
//         />
 
//     );
//   };



// const NewsPostPL() => {
//     return (
//         <div className={useStyles().postBox}>

//             <div className={useStyles().post}>
//                 {/* <DividerMini /> */}
//                 <div className={useStyles().postHeader}>
//                     <div className={useStyles().postTitle}>Wernisaż Magical Dreams</div>
//                     <div className={useStyles().postDate}>22&nbsp;września&nbsp;2021&nbsp;r.</div>
//                 </div>
//                 <div className={useStyles().postText}>
//                     <img src={POSTimages[3].src} alt={POSTimages[3].alt} className={useStyles().postPhoto}></img>
//                     W zeszłą sobotę, w galerii Bator. Wielkie święto realizmu fantastycznego, 
//                     wernisaż Magical Dreams. Dzieła mistrzów wyobraźni z Ameryki, Meksyku, Armenii, 
//                     Włoch, Francji, Austrii, Ukrainy, Rosji, Niemiec i Polski. Miłe spotkanie z 
//                     artystkami i artystami, kolekcjonerami, galerzystami, przyjaciółmi. To nie 
//                     przypadek, że ta wystawa odbywa się w Szczyrku, a więc w górach, a więc w 
//                     przestrzeni wolności (także twórczej), nieskrępowanej imaginacji, swobodnych 
//                     skojarzeń, wybujałej fantazji. W górach są latające smoki, gnomy w jaskiniach, 
//                     jednorożce i chimery, gryfy, olbrzymy, śpiący rycerze, szklane szczyty, erupcje 
//                     wulkanów, erupcje fantazji. „W górach jest wszystko co kocham” – jak pisał poeta.
//                     W górach, jak wiadomo, nie przyjęło się prawo budowlane. I namordniki czyli tzw. 
//                     maseczki też nie za bardzo. Ale za to po oficjalnym otwarciu, podczas bankietu 
//                     goście przyjęli pierwszą dawkę. Cytrynówki oczywiście. A potem kolejne… I tak coś 
//                     do trzeciej nad ranem.
//                 </div>           
//                 <div className={useStyles().morePhotosBox}>
//                 <img src={POSTimages[4].src} alt={POSTimages[4].alt} className={useStyles().morePhotos}></img>
//                 <img src={POSTimages[5].src} alt={POSTimages[5].alt} className={useStyles().morePhotos}></img> 
//                 <img src={POSTimages[6].src} alt={POSTimages[6].alt} className={useStyles().morePhotos}></img>
//                 <img src={POSTimages[7].src} alt={POSTimages[7].alt} className={useStyles().morePhotos}></img>
//                 <img src={POSTimages[8].src} alt={POSTimages[8].alt} className={useStyles().morePhotos}></img>
//                 <img src={POSTimages[9].src} alt={POSTimages[9].alt} className={useStyles().morePhotos}></img>
//                 <img src={POSTimages[10].src} alt={POSTimages[10].alt} className={useStyles().morePhotos}></img>
//                 <img src={POSTimages[11].src} alt={POSTimages[11].alt} className={useStyles().morePhotos}></img>
//                 <img src={POSTimages[12].src} alt={POSTimages[12].alt} className={useStyles().morePhotos}></img>
//                 <img src={POSTimages[13].src} alt={POSTimages[13].alt} className={useStyles().morePhotos}></img>
                

//                 </div> 
//                 </div>
//                 </div>
                
//     const postImages = POSTimages;
  
//     const [imageToShow, setImageToShow] = useState("");
//     const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  
  
  
//     const showImage = (index) => {
//       setImageToShow(index);
//       setLightBoxDisplay(true);
//     };
  
//     const hideLightBox = () => {
//       setLightBoxDisplay(false);
//     };

//     const showNext = (e) => {
//       e.stopPropagation();
//       let currentIndex = imageToShow;
//       if (currentIndex >= allImages.length - 1) {
//         setImageToShow(0);
//       } else {
//         setImageToShow(currentIndex + 1);
//       }
//     };
  
//     const showPrev = (e) => {
//       e.stopPropagation();
//       let currentIndex = imageToShow;
//       if (currentIndex <= 0) {
//         setImageToShow(allImages.length - 1);
//       } else {
//         setImageToShow(currentIndex - 1);
//       }
//     };
  
//     return (
  
//       <div>
  
     
  
//             {POSTimages.map((image, index) => {
//               return (
//                 <PostGalleryPhotos
//                   image={image}
//                   index={index}
//                   show={showImage}
//                 />
//               );
//             })}
      
  
//           {
//             lightboxDisplay ?
//               <div id="lightbox" onClick={hideLightBox}>
//                 <button onClick={showPrev}><GrPrevious className="modal__slide-icon"/></button>
//                 <img id="lightbox-img" src={allImages[imageToShow].src} alt={allImages[imageToShow].alt}></img>
//                 <button onClick={showNext}><GrNext className="modal__slide-icon"></GrNext></button>
//               </div>
//               : ""
//           }
//       </div>
//     );
//   }