import React from 'react';
import './DividerMini.css';

const DividerMini = () => {
  
    return (
        <div className="divider-mini div-transparent"></div>
    )
}

export default DividerMini;